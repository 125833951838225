import { Helmet } from "react-helmet";
import { Header } from "fragments/header";
import { Footer } from "fragments/footer";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { number } from "constants";
import { HiPhone } from "react-icons/hi";

export default function Layout({ children, title }) {
  return (
    <>
      {/* <Helmet> */}
        {/* <title>{title} - Best Flight, Holiday Packages & Hotel Booking</title> */}
        {/* <meta name="description" content="Find the best holiday packages and flight deals with Euro Holidays." /> */}
        {/* <meta name="keywords" content="euro holidays, holiday packages, flight deals, hotel bookings, Visa Services, Euroholidays, Eu Holidays, Uk Flights" /> */}
        {/* <meta property="og:title" content="Euro Holidays | Best Flight, Holiday Packages & Hotel Booking" /> */}
        {/* <meta property="og:description" content="Find the best holiday packages and flight deals with Euro Holidays." /> */}
        {/* <meta property="og:description" content="Euro Holidays - The best choice for flight bookings, hotel reservations, and holiday packages.  */}
        {/* // Explore top travel deals and book affordable getaways for unforgettable experiences. Plan your perfect vacation with Euro Holidays now!" /> */}
       

      {/* </Helmet> */}
      <Header />
      <div className="min-h-[50vh]">{children}</div>
      <div className="fixed flex flex-col gap-2 bottom-6 right-6 z-10">
        <a href="https://wa.me/+442080048744" target="_blank" rel="noreferrer">
          <button
            className="bg-green-500 text-white p-4 rounded-full shadow-xl hover:bg-green-600 focus:outline-none focus:ring focus:border-green-300 transition duration-300"
            onClick={() => {
              // Handle WhatsApp button click
              console.log("WhatsApp button clicked");
            }}
          >
            <AiOutlineWhatsApp size={24} />
          </button>
        </a>
        <a href="tel:02080048744">
          <button
            className="bg-primary/80 text-white p-4 rounded-full shadow-xl hover:bg-primary focus:outline-none focus:ring focus:border-primary transition duration-300"
            onClick={() => {
              // Handle WhatsApp button click
              console.log("WhatsApp button clicked");
            }}
          >
            <HiPhone size={24} />
          </button>
        </a>
      </div>
      <Footer />
    </>
  );
}

Layout.defaultProps = {
  title: "Euro Holidays",
  description: "",
  keywords: "noindex, nofollow",
};
