import Layout from "fragments/layout/layout";
import React from "react";

export function TermsConditions() {
  return (
    <Layout class="bg-gray-100 p-4">
      <div class="max-w-4xl my-32 mx-auto bg-white p-8 rounded-xl shadow-lg">
        <h1 class="text-3xl font-bold mb-4">Terms & Conditions</h1>

        <h2 class="text-xl font-semibold mb-2">Understanding</h2>
        <p class="mb-4">
          The statements of which the basic letter is advanced have suggestions
          portrayed under the going with conditions. The going definitions will
          have a comparative significance whether they appear there or psyche in
          the plural.
        </p>

        <h2 class="text-xl font-semibold mb-2">Definitions</h2>
        <p class="mb-4">
          For the motivations behind these Agreements: Subsidiary means a
          substance that controls is constrained by, or is under normal control
          with a party, where "control" signifies responsibility for or a
          greater amount of the offers, value interest, or different protections
          qualified for a vote in favor of the appointment of chiefs or other
          overseeing authority. Country alludes to: Joined Realm Organization
          (alluded to as all things considered "the Organization", "We", "Us' '
          or "Our" in this Understanding) alludes to Quick Occasions. Gadget
          implies any gadget that can get to Help like a PC, a cell phone, or a
          computerized tablet. Administration alludes to the Site. Arrangements
          (also implied as "Terms'') mean these Arrangements that structure the
          entire comprehension among You and the Association concerning the
          usage of the Assistance. This Arrangements understanding has been made
          with the help of the Free Arrangements Generator. Outsider Online
          Entertainment Administration implies any administrations or content
          (counting information, data, items, or administrations) given by an
          outsider that might be shown, included, or made accessible by the
          Help. The site alludes to Quick Occasions, open from
          https://fastholidays.co.uk You imply the individual getting to or
          utilizing the Help, or the organization, or other lawful substance for
          the benefit of which such individual is getting to or utilizing the
          Help, as appropriate.
        </p>

        <h2 class="text-xl font-semibold mb-2">Affirmation</h2>
        <p class="mb-4">
          These are the Arrangements overseeing the use of this Help and the
          game plan that works for You and the Association. These Agreements set
          out the freedoms and commitments of all clients regarding the
          utilization of the Help. Your admittance to and utilization of the
          Assistance is molded on Your acknowledgment of and consistency with
          these Agreements. These arrangements apply to all visitors, clients,
          and others who access or use the Assistance. By getting to or
          utilizing the Help You with consenting to be confined by these Game
          plans. If You can't fight the temptation to conflict with any piece of
          these Plans, You may not get the Assistance. You address that you are
          recent years old. The Organization doesn't allow those under 18 to
          utilize the Help. Your admittance to and utilization of the Help is
          additionally molded on Your acknowledgment of and consistency with the
          Security Strategy of the Organization. Our Security Strategy portrays
          Our arrangements and systems on the assortment, use, and divulgence of
          Your data when You utilize the Application or the Site and enlightens
          concerning Your assurance honors and how the law shields You. If it's
          not too much trouble, read Our Security Strategy cautiously before
          utilizing Our Administration.
        </p>
        <h2 class="text-xl font-semibold mb-2">
          Connections to Different Sites
        </h2>
        <p class="mb-4">
          Our Organization could contain associations with pariah destinations
          or organizations that are not asserted or compelled by the
          Association. The Organization has zero influence over and takes care
          of, the substance, security courses of action, or practices of any
          untouchable destinations or organizations. You further recognize and
          concur that the Organization will not be capable or responsible,
          straightforwardly or by implication, for any harm or misfortune caused
          or asserted to be brought about by or regarding the utilization of or
          dependence on any such happy, labor and products accessible on or
          through any such sites or administrations. We emphatically encourage
          You to peruse the agreements and protection strategies of any outsider
          sites or administrations that You visit.
        </p>
        <h2 class="text-xl font-semibold mb-2">End</h2>
        <p class="mb-4">
          We might end or suspend Your entrance right away, without earlier
          notification or obligation, under any circumstance, including without
          constraint on the off chance that You break these Agreements. Upon
          end, Your capability to utilize the Assist with a willing stop right
          away.
        </p>
        <h2 class="text-xl font-semibold mb-2">Restriction of Risk</h2>
        <p class="mb-4">
          Notwithstanding any damages that You could cause, the entire gamble of
          the Association and any of its suppliers under any plan of this Terms
          and Your world-class answer for the prior will be all restricted to
          the sum paid by You through the Help or 100 USD on the off chance that
          You haven't bought anything through the Assistance. To the most
          extreme degree allowed by material regulation, on no occasion will the
          Organization or its providers be at risk for any exceptional,
          accidental, roundabout, or considerable harm by any stretch of the
          imagination (counting, yet not confined to, hurts for loss of
          advantages, loss of data or different information, for business
          impedance, for individual injury, loss of safety arising out of or in
          any capacity connected with the utilization of or powerlessness to
          utilize the Assistance, outsider programming and additionally outsider
          equipment utilized with the Help, or generally regarding any
          arrangement of this Terms), regardless of whether the Organization or
          any provider has been educated concerning the chance of such harms and
          regardless of whether the cure comes up short of its fundamental
          reason. A few states don't permit the rejection of inferred guarantees
          or constraint of obligation for coincidental or considerable harms,
          and that implies that a portion of the above impediments may not
          matter. In these states, each party's obligation will be restricted to
          the furthest degree allowed by regulation.
        </p>
        <h2 class="text-xl font-semibold mb-2">
          "With no guarantees" and "AS Accessible"
        </h2>
        <p class="mb-4">
          The Help is given to You "With no guarantees" and "AS Accessible" and
          with all shortcomings and imperfections without guarantee of any sort.
          To the most extreme degree allowed under relevant regulation, the
          Organization, for its own sake and the benefit of its Partners and
          their licensors and specialist co-ops, explicitly renounces all
          guarantees, whether express, suggested, legal, etc., as for the Help,
          including all inferred guarantees of merchantability, qualification
          for a specific reason, title and non-encroachment, and guarantees that
          might emerge out obviously of managing, course of execution,
          utilization or exchange practice. Without limit to the previous, the
          Organization gives no guarantee or undertaking. It makes no portrayal
          of any sort that the Assistance will meet Your prerequisites,
          accomplish any expected outcomes, be viable or work with some other
          programming, applications, frameworks, or administrations, work
          without interference, meet any exhibition or dependability guidelines,
          or be sans mistake or that any blunders or deformities would be able
          or will be remedied. Without restricting the prior, neither the
          Organization nor any of the organization's suppliers make any
          portrayal or guarantee of any sort, express or suggested: (I)
          regarding the activity or accessibility of the Help, or the data,
          content, and materials or items included subsequently; (ii) that the
          Assistance will be continuous or mistake free; (iii) concerning the
          precision, unwavering quality, or cash of any data or content offered
          through the Support; or (iv) that the Assistance, its servers, the
          substance, or messages sent from or for the Organization are liberated
          from infections, scripts, diversions, worms, malware, timebombs or
          other hurtful parts. A few purviews don't permit the rejection of
          particular kinds of guarantees or limits on pertinent legal privileges
          of a shopper, so some of the above prohibitions as a whole and
          restrictions may not concern You. Yet, in such a case the prohibitions
          and limits set out in this segment will be applied furthest degree
          enforceable under material regulation.
        </p>
        <h2 class="text-xl font-semibold mb-2">Debates Goal</h2>
        <p class="mb-4">
          Assuming You have any worries or questions about the Help, You consent
          to initially attempt to determine the debate casually by reaching the
          Organization.
        </p>

        <h2 class="text-xl font-semibold mb-2">
          For European Association (EU) Clients
        </h2>
        <p class="mb-4">
          On the off chance that You are a European Association shopper, you
          will profit from any compulsory arrangements of the law of the country
          in which you are an occupant.
        </p>

        <h2 class="text-xl font-semibold mb-2">US Lawful Consistency</h2>
        <p class="mb-4">
          You address and warrant that (I) You are not situated in a country
          that is dependent upon the US government ban, or that has been
          assigned by the US government as a "psychological militant supporting"
          nation, and (ii) You are not recorded on any US government rundown of
          precluded or confined parties.
        </p>

        <h2 class="text-xl font-semibold mb-2">Severability</h2>
        <p class="mb-4">
          Expecting any plan of these Terms is held to be unenforceable or
          invalid, such course of action will be changed and unraveled to
          accomplish the objectives of such plan furthest degree possible under
          the material guideline, and the overabundance plans will happen in
          full power and effect.
        </p>

        <h2 class="text-xl font-semibold mb-2">Waiver</h2>
        <p class="mb-4">
          Other than as given in this, the powerlessness to rehearse a right or
          to require execution of responsibility under these Terms won't
          influence a party's ability to exercise such right or require such
          execution whenever from that point nor will the waiver of a break
          comprise a waiver of any ensuing break.
        </p>

        <h2 class="text-xl font-semibold mb-2">Interpretation Understanding</h2>
        <p class="mb-4">
          These Arrangements could have been deciphered expecting We have made
          them open to You on our Organization. You agree that the main English
          text will win by an inquiry.
        </p>

        <h2 class="text-xl font-semibold mb-2">Changes to These Agreements</h2>
        <p class="mb-4">
          We hold the right, at Our only caution, to alter or supplant these
          Terms whenever. On the off chance that a modification is a material,
          We will put forth sensible attempts to give no less than 30 days'
          notification before any new terms produce results. What is a material
          change still up in the air Our only caution. By proceeding to access
          or utilize Our Administration after those amendments become viable,
          You consent to be limited by the changed terms. On the off chance that
          You don't consent to the new terms, in entire or to a limited extent,
          if it's not too much trouble, quit utilizing the site and the Help.
        </p>

        <h2 class="text-xl font-semibold mb-2">Contact Us</h2>
        <p class="mb-4">
          If you have any questions about this Privacy Policy, You can contact
          us: By visiting this page on our website:euroholidays.co.uk
        </p>

        <p class="mb-4"> By phone number: 02080048744</p>
      </div>
    </Layout>
  );
}
