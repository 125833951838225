export const data = [
  {
    city: "a-coruna",
    airport: "a-coruna-airport",
    airportCode: "LCG",
    country: "spain",
  },
  {
    city: "abuja",
    airport: "abuja-airport",
    airportCode: "ABV",
    country: "nigeria",
  },
  {
    city: "abidjan",
    airport: "abidjan-airport",
    airportCode: "ABJ",
    country: "ivory-coast",
  },
  {
    city: "aberdeen",
    airport: "aberdeen-airport",
    airportCode: "ABZ",
    country: "united-kingdom",
  },
  {
    city: "abu-dhabi",
    airport: "abu-dhabi-airport",
    airportCode: "AUH",
    country: "uae",
  },
  {
    city: "accra",
    airport: "kotoka-airport",
    airportCode: "ACC",
    country: "ghana",
  },
  {
    city: "adelaide",
    airport: "adelaide-airport",
    airportCode: "ADL",
    country: "australia",
  },
  {
    city: "agadir",
    airport: "al-massiraa-airport",
    airportCode: "AGA",
    country: "morocco",
  },
  {
    city: "ahmedabad",
    airport: "sardar-vallabhbhai-patel-airport",
    airportCode: "AMD",
    country: "india",
  },
  {
    city: "al-ain",
    airport: "etihad",
    airportCode: "ZVH",
    country: "uae",
  },
  {
    city: "alesund",
    airport: "vigra-airport",
    airportCode: "AES",
    country: "norway",
  },
  {
    city: "alexandria",
    airport: "borg-el-arab-airport",
    airportCode: "HBE",
    country: "egypt",
  },
  {
    city: "algiers",
    airport: "houari-boumediene-airport",
    airportCode: "ALG",
    country: "algeria",
  },
  {
    city: "alicante",
    airport: "alicante-airport",
    airportCode: "ALC",
    country: "spain",
  },
  {
    city: "alice-springs",
    airport: "alice-springs-airport",
    airportCode: "ASP",
    country: "australia",
  },
  {
    city: "alor-setar",
    airport: "sultan-abdul-halim-airport",
    airportCode: "AOR",
    country: "malaysia",
  },
  {
    city: "amman",
    airport: "queen-alia-airport",
    airportCode: "AMM",
    country: "jordan",
  },
  {
    city: "amritsar",
    airport: "sri-guru-ram-dass-jee-airport ",
    airportCode: "ATQ",
    country: "india",
  },
  {
    city: "amsterdam",
    airport: "schiphol-airport",
    airportCode: "AMS",
    country: "netherlands",
  },
  {
    city: "ancona",
    airport: "falconara-airport",
    airportCode: "AOI",
    country: "italy",
  },
  {
    city: "angers",
    airport: "saint-laud-TGV",
    airportCode: "OXG",
    country: "france",
  },
  {
    city: "assuit",
    airport: "assuit-airport",
    airportCode: "ATZ",
    country: "egypt",
  },
  {
    city: "asuncion",
    airport: "silvio-pettirossi-airport",
    airportCode: "ASU",
    country: "paraguay",
  },
  {
    city: "athens",
    airport: "eleftherios-venizelos-airport",
    airportCode: "ATH",
    country: "greece",
  },
  {
    city: "atlanta-GA",
    airport: "hartsfield-jackson-airport",
    airportCode: "ATL",
    country: "usa",
  },
  {
    city: "austin-TX",
    airport: "bergstrom-airport",
    airportCode: "AUS",
    country: "usa",
  },
  {
    city: "auckland",
    airport: "auckland-airport",
    airportCode: "AKL",
    country: "new-Zealand",
  },
  {
    city: "avignon",
    airport: "avignon-TGV",
    airportCode: "XZN",
    country: "france",
  },
  {
    city: "bagdogra",
    airport: "bagdogra-airport",
    airportCode: "IXB",
    country: "india",
  },
  {
    city: "baghdad",
    airport: "baghdad-airport",
    airportCode: "BGW",
    country: "iraq",
  },
  {
    city: "bahrain",
    airport: "bahrain-airport",
    airportCode: "BAH",
    country: "bahrain",
  },
  {
    city: "birmingham",
    airport: "birmingham-airport",
    airportCode: "BHX",
    country: "united-kingdom",
  },
  {
    city: "baku",
    airport: "heydar-aliyev-airport",
    airportCode: "GYD",
    country: "azerbaijan",
  },
  {
    city: "bali",
    airport: "ngurah-rai-airport",
    airportCode: "DPS",
    country: "indonesia",
  },
  {
    city: "balikpapan",
    airport: "sepinggan-airport",
    airportCode: "BPN",
    country: "indonesia",
  },
  {
    city: "ballina",
    airport: "byron-gateway-airport",
    airportCode: "BNK",
    country: "australia",
  },
  {
    city: "baltimore-MD",
    airport: "washington-thurgood-marshall-airport",
    airportCode: "BWI",
    country: "usa",
  },
  {
    city: "barcelona",
    airport: "el-prat-airport ",
    airportCode: "BCN",
    country: "spain",
  },
  {
    city: "bari",
    airport: "bari-central",
    airportCode: "BAU",
    country: "italy",
  },
  {
    city: "bari",
    airport: "palese-airport",
    airportCode: "BRI",
    country: "italy",
  },
  {
    city: "basel",
    airport: "basel-badischer",
    airportCode: "ZBA",
    country: "switzerland",
  },
  {
    city: "basel",
    airport: "euro-airport",
    airportCode: "BSL",
    country: "switzerland",
  },
  {
    city: "beijing",
    airport: "beijing-all-airports",
    airportCode: "BJS",
    country: "china",
  },
  {
    city: "beirut",
    airport: "rafic-hariri-airport",
    airportCode: "BEY",
    country: "lebanon",
  },
  {
    city: "belfast",
    airport: "george-best-city-airport ",
    airportCode: "BHD",
    country: "united-kingdom",
  },
  {
    city: "belgrade",
    airport: "nikola-tesla-airport",
    airportCode: "BEG",
    country: "serbia",
  },
  {
    city: "bengaluru",
    airport: "kempegowda-airport",
    airportCode: "BLR",
    country: "india",
  },
  {
    city: "bergen",
    airport: "fleslan-airport",
    airportCode: "BGO",
    country: "norway",
  },
  {
    city: "berlin",
    airport: "berlin-central",
    airportCode: "QPP",
    country: "germany",
  },
  {
    city: "berlin",
    airport: "brandnburg-airport",
    airportCode: "BER",
    country: "germany",
  },
  {
    city: "bhopal",
    airport: "bhopal-airport",
    airportCode: "BHO",
    country: "india",
  },
  {
    city: "bhubaneswar",
    airport: "biju-patnaik-airport",
    airportCode: "BBI",
    country: "india",
  },
  {
    city: "bilbao",
    airport: "bilbao-airport",
    airportCode: "BIO",
    country: "spain",
  },
  {
    city: "billund",
    airport: "billund-airport",
    airportCode: "BLL",
    country: "denmark",
  },
  {
    city: "bintulu",
    airport: "bintulu-airport",
    airportCode: "BTU",
    country: "malaysia",
  },
  {
    city: "birmingham-AL",
    airport: "shuttlesworth-airport ",
    airportCode: "BHM",
    country: "usa",
  },
  {
    city: "bloomington-normal-IL",
    airport: "normal-airport ",
    airportCode: "BMI",
    country: "usa",
  },
  {
    city: "bologna",
    airport: "bologna-central",
    airportCode: "IBT",
    country: "italy",
  },
  {
    city: "bogota",
    airport: "el-dorado-airport",
    airportCode: "BOG",
    country: "colombia",
  },
  {
    city: "bordeaux",
    airport: "merignac-airport",
    airportCode: "BOD",
    country: "france",
  },
  {
    city: "bordeaux",
    airport: "saint-jean-TGV",
    airportCode: "ZFQ",
    country: "france",
  },
  {
    city: "beijing",
    airport: "beijing-capital",
    airportCode: "PEK",
    country: "china",
  },
  {
    city: "bologna",
    airport: "guglielmo-marconi-airport",
    airportCode: "BLQ",
    country: "italy",
  },
  {
    city: "boston-MA",
    airport: "lLogan-airport",
    airportCode: "BOS",
    country: "usa",
  },
  {
    city: "brindisi",
    airport: "brindisi",
    airportCode: "BQD",
    city: "brindisi",
  },
  {
    city: "brindisi",
    airport: "casale-airport",
    airportCode: "BDS",
    city: "brindisi",
  },
  {
    city: "brisbane",
    airport: "brisbane-airport",
    airportCode: "BNE",
    country: "australia",
  },
  {
    city: "bristol",
    airport: "lulsgate-airport",
    airportCode: "BRS",
    country: "united-kingdom",
  },
  {
    city: "bulawayo",
    airport: "bulawayo-airport",
    airportCode: "BUQ",
    country: "zimbabwe",
  },
  {
    city: "brussels",
    airport: "brussels-airport",
    airportCode: "BRU",
    country: "belgium",
  },
  {
    city: "brussels",
    airport: "midi",
    airportCode: "ZYR",
    country: "belgium",
  },
  {
    city: "bucharest",
    airport: "henri-coanda-otopeni-airport",
    airportCode: "OTP",
    country: "romania",
  },
  {
    city: "budapest",
    airport: "liszt-ferenc-airport",
    airportCode: "BUD",
    country: "hungary",
  },
  {
    city: "buenos-aires",
    airport: "ministro-pistarini-airport",
    airportCode: "EZE",
    country: "argentina",
  },
  {
    city: "buffalo-NY",
    airport: "niagara-airport",
    airportCode: "BUF",
    country: "usa",
  },
  {
    city: "burbank-CA",
    airport: "bob-hope-airport",
    airportCode: "BUR",
    country: "usa",
  },
  {
    city: "burlington-VT",
    airport: "burlington-airport",
    airportCode: "BTV",
    country: "usa",
  },
  {
    city: "cabries",
    airport: "aix-en-provence-TGV",
    airportCode: "QXB",
    country: "france",
  },
  {
    city: "cagliari",
    airport: "elmas-airport",
    airportCode: "CAG",
    country: "italy",
  },
  {
    city: "cairns",
    airport: "cairns-airport",
    airportCode: "CNS",
    country: "australia",
  },
  {
    city: "cairo",
    airport: "cairo-airport",
    airportCode: "CAI",
    country: "egypt",
  },
  {
    city: "calgary",
    airport: "calgary-airport",
    airportCode: "YYC",
    country: "canada",
  },
  {
    city: "canberra",
    airport: "canberra-airport",
    airportCode: "CBR",
    country: "australia",
  },
  {
    city: "cancun",
    airport: "cancun-airport",
    airportCode: "CUN",
    country: "mexico",
  },
  {
    city: "cardiff",
    airport: "cardiff-wales-airport",
    airportCode: "CWL",
    country: "united-kingdom",
  },
  {
    city: "casablanca",
    airport: "mohammed-v-airport",
    airportCode: "CMN",
    country: "morocco",
  },
  {
    city: "caserta",
    airport: "caserta",
    airportCode: "CTJ",
    country: "italy",
  },
  {
    city: "catania",
    airport: "fontanarossa-airport",
    airportCode: "CTA",
    country: "italy",
  },
  {
    city: "cedar-rapids-ia",
    airport: "the-eastern-iowa-airport",
    airportCode: "CID",
    country: "usa",
  },
  {
    city: "champaign-il",
    airport: "willard-university-airport",
    airportCode: "CMI",
    country: "usa",
  },
  {
    city: "chandigarh",
    airport: "chandigarh-airport",
    airportCode: "IXC",
    country: "india",
  },
  {
    city: "chania",
    airport: "ioannis-daskalogiannis-airport",
    airportCode: "chq",
    country: "greece",
  },
  {
    city: "charleston-sc",
    airport: "air-force-base-airport",
    airportCode: "chs",
    country: "usa",
  },
  {
    city: "chiang-mai",
    airport: "chiang-mai-airport",
    airportCode: "CNX",
    country: "thailand",
  },
  {
    city: "chicago-il",
    airport: "ohare-airport",
    airportCode: "ORD",
    country: "usa",
  },
  {
    city: "chios",
    airport: "chios-airport",
    airportCode: "JKH",
    country: "greece",
  },
  {
    city: "chittagong",
    airport: "shah-amanat-airport",
    airportCode: "CGP",
    country: "bangladesh",
  },
  {
    city: "christchurch",
    airport: "christchurch-airport",
    airportCode: "CHC",
    country: "new-zealand",
  },
  {
    city: "cincinnati-oh",
    airport: "covington-northern-kentucky-airport",
    airportCode: "CVG",
    country: "usa",
  },
  {
    city: "cleveland-oh",
    airport: "hopkins-airport",
    airportCode: "CLE",
    country: "usa",
  },
  {
    city: "coffs-harbour",
    airport: "coffs-harbour-airport",
    airportCode: "CFS",
    country: "australia",
  },
  {
    city: "cologne",
    airport: "cologne-bonn-airport",
    airportCode: "CGN",
    country: "germany",
  },
  {
    city: "cologne",
    airport: "cologne",
    airportCode: "QKL",
    country: "germany",
  },
  {
    city: "colombo",
    airport: "bandaranaike-airport",
    airportCode: "CMB",
    country: "sri-lanka",
  },
  {
    city: "columbia-mo",
    airport: "columbia-regional-airport",
    airportCode: "COU",
    country: "usa",
  },
  {
    city: "columbus-oh",
    airport: "port-columbus-airport",
    airportCode: "CMH",
    country: "usa",
  },
  {
    city: "corfu",
    airport: "ioannis-kapodistrias-airport",
    airportCode: "CFU",
    country: "greece",
  },
  {
    city: "cork",
    airport: "cork-airport",
    airportCode: "ORK",
    country: "ireland",
  },
  {
    city: "da-nang",
    airport: "da-nang-airport",
    airportCode: "DAD",
    country: "vietnam",
  },
  {
    city: "dakar",
    airport: "blaise-diagne-airport",
    airportCode: "DSS",
    country: "senegal",
  },
  {
    city: "dalian",
    airport: "zhoushuizi-airport",
    airportCode: "DLC",
    country: "china",
  },
  {
    city: "dallas-tx",
    airport: "fort-worth-airport",
    airportCode: "DFW",
    country: "usa",
  },
  {
    city: "dammam",
    airport: "king-fahd-airport",
    airportCode: "DMM",
    country: "saudi-arabia",
  },
  {
    city: "dar-es-salaam",
    airport: "julius-nyerere-airport",
    airportCode: "DAR",
    country: "tanzania",
  },
  {
    city: "darwin",
    airport: "darwin-airport",
    airportCode: "DRW",
    country: "australia",
  },
  {
    city: "dayton-oh",
    airport: "james-m-cox-airport",
    airportCode: "DAY",
    country: "usa",
  },
  {
    city: "denver-co",
    airport: "denver-airport",
    airportCode: "DEN",
    country: "usa",
  },
  {
    city: "des-moines-ia",
    airport: "des-moines-airport",
    airportCode: "DSM",
    country: "usa",
  },
  {
    city: "detroit-mi",
    airport: "wayne-county-metro-airport",
    airportCode: "DTW",
    country: "usa",
  },
  {
    city: "dhaka",
    airport: "hazrat-shahjalal-airport",
    airportCode: "DAC",
    country: "bangladesh",
  },
  {
    city: "doha",
    airport: "doha-airport",
    airportCode: "DIA",
    country: "qatar",
  },
  {
    city: "doha",
    airport: "hamad-airport",
    airportCode: "DOH",
    country: "qatar",
  },
  {
    city: "donegal",
    airport: "donegal-airport",
    airportCode: "CFN",
    country: "ireland",
  },
  {
    city: "dortmund",
    airport: "dortmund-airport",
    airportCode: "DTM",
    country: "germany",
  },
  {
    city: "dresden",
    airport: "dresden-airport",
    airportCode: "DRS",
    country: "germany",
  },
  {
    city: "dubai",
    airport: "etihad",
    airportCode: "XNB",
    country: "uae",
  },
  {
    city: "dublin",
    airport: "dublin-airport",
    airportCode: "DUB",
    country: "ireland",
  },
  {
    city: "dubrovnik",
    airport: "dubrovnik-airport",
    airportCode: "DBV",
    country: "croatia",
  },
  {
    city: "dubuque-ia",
    airport: "dubuque-regional-airport",
    airportCode: "DBQ",
    country: "usa",
  },
  {
    city: "dunedin",
    airport: "dunedin-airport",
    airportCode: "DUD",
    country: "new-zealand",
  },
  {
    city: "dusseldorf",
    airport: "dusseldorf-airport",
    airportCode: "DUS",
    country: "germany",
  },
  {
    city: "dusseldorf",
    airport: "dusseldorf",
    airportCode: "QDU",
    country: "germany",
  },
  {
    city: "dar-es-salaam",
    airport: "dar-es-salaam-airport",
    airportCode: "DAR",
    country: "tanzania",
  },
  {
    city: "edinburgh",
    airport: "edinburgh-airport",
    airportCode: "EDI",
    country: "united-kingdom",
  },
  {
    city: "edmonton",
    airport: "edmonton-airport",
    airportCode: "YEG",
    country: "canada",
  },
  {
    city: "el-paso-tx",
    airport: "el-paso-airport",
    airportCode: "ELP",
    country: "usa",
  },
  {
    city: "emerald",
    airport: "emerald-airport",
    airportCode: "EMD",
    country: "australia",
  },
  {
    city: "entebbe",
    airport: "entebbe-airport",
    airportCode: "EBB",
    country: "uganda",
  },
  {
    city: "ercan",
    airport: "ercan-airport",
    airportCode: "ECN",
    country: "cyprus",
  },
  {
    city: "eugene-or",
    airport: "mahlon-sweet-field-airport",
    airportCode: "EUG",
    country: "usa",
  },
  {
    city: "evansville-in",
    airport: "evansville-airport",
    airportCode: "EVV",
    country: "usa",
  },
  {
    city: "faisalabad",
    airport: "faisalabad-airport",
    airportCode: "LYP",
    country: "pakistan",
  },
  {
    city: "fargo-nd",
    airport: "hector-airport",
    airportCode: "FAR",
    country: "usa",
  },
  {
    city: "faro",
    airport: "faro-airport",
    airportCode: "FAO",
    country: "portugal",
  },
  {
    city: "fayetteville-ar",
    airport: "northwest-arkansas-airport",
    airportCode: "XNA",
    country: "usa",
  },
  {
    city: "flint-MI",
    airport: "bishop-airport",
    airportCode: "FNT",
    country: "usa",
  },
  {
    city: "florence",
    airport: "firenze-santa-maria-novella",
    airportCode: "ZMS",
    country: "italy",
  },
  {
    city: "florence",
    airport: "peretola-airport",
    airportCode: "FLR",
    country: "italy",
  },
  {
    city: "fort-lauderdale-FL",
    airport: "hollywood-airport",
    airportCode: "FLL",
    country: "usa",
  },
  {
    city: "fort-myers-FL",
    airport: "southwest-florida-airport",
    airportCode: "RSW",
    country: "usa",
  },
  {
    city: "fort-wayne-IN",
    airport: "fort-wayne-airport",
    airportCode: "FWA",
    country: "usa",
  },
  {
    city: "frankfurt",
    airport: "frankfurt-airport",
    airportCode: "FRA",
    country: "germany",
  },
  {
    city: "fresno-CA",
    airport: "yosemite-airport",
    airportCode: "FAT",
    country: "usa",
  },
  {
    city: "fuerteventura",
    airport: "fuerteventura-airport",
    airportCode: "FUE",
    country: "spain",
  },
  {
    city: "fukuoka",
    airport: "fukuoka-airport",
    airportCode: "FUK",
    country: "japan",
  },
  {
    city: "gaborone",
    airport: "sir-seretse-khama-airport",
    airportCode: "GBE",
    country: "botswana",
  },
  {
    city: "gan-island",
    airport: "gan-airport",
    airportCode: "GAN",
    country: "maldives",
  },
  {
    city: "gaziantep",
    airport: "oguzeli-airport",
    airportCode: "GZT",
    country: "turkey",
  },
  {
    city: "gdansk",
    airport: "lech-walesa-airport",
    airportCode: "GDN",
    country: "poland",
  },
  {
    city: "geneva",
    airport: "geneva-airport",
    airportCode: "GVA",
    country: "switzerland",
  },
  {
    city: "genova",
    airport: "cristoforo-colombo-airport",
    airportCode: "GOA",
    country: "italy",
  },
  {
    city: "glasgow",
    airport: "glasgow-airport",
    airportCode: "GLA",
    country: "united-kingdom",
  },
  {
    city: "goa",
    airport: "dabolim-airport",
    airportCode: "GOI",
    country: "india",
  },
  {
    city: "gold-coast",
    airport: "coolangatta-airport",
    airportCode: "OOL",
    country: "australia",
  },
  {
    city: "goteborg",
    airport: "landvetter-airport",
    airportCode: "GOT",
    country: "sweden",
  },
  {
    city: "gran-canaria",
    airport: "gran-canaria-airport",
    airportCode: "LPA",
    country: "spain",
  },
  {
    city: "grand-rapids-MI",
    airport: "gerald-R-ford-airport",
    airportCode: "GRR",
    country: "usa",
  },
  {
    city: "graz",
    airport: "graz-airport",
    airportCode: "GRZ",
    country: "austria",
  },
  {
    city: "green-bay-WI",
    airport: "austin-straubel-airport",
    airportCode: "GRB",
    country: "usa",
  },
  {
    city: "greensboro-NC",
    airport: "piedmont-triad-airport",
    airportCode: "GSO",
    country: "usa",
  },
  {
    city: "greenville-SC",
    airport: "spartanburg-airport",
    airportCode: "GSP",
    country: "usa",
  },
  {
    city: "guangzhou",
    airport: "baiyun-airport",
    airportCode: "CAN",
    country: "china",
  },
  {
    city: "guwahati",
    airport: "lokpriya-gopinath-bordoloi-airport",
    airportCode: "GAU",
    country: "india",
  },
  {
    city: "halifax",
    airport: "stanfield-airport",
    airportCode: "YHZ",
    country: "canada",
  },
  {
    city: "hamburg",
    airport: "fuhlsbuettel-airport",
    airportCode: "HAM",
    country: "germany",
  },
  {
    city: "hamburg",
    airport: "hamburg",
    airportCode: "ZMB",
    country: "germany",
  },
  {
    city: "harare",
    airport: "harare-airport",
    airportCode: "HRE",
    country: "zimbabwe",
  },
  {
    city: "hamilton-island",
    airport: "great-barrier-reef-airport",
    airportCode: "HTI",
    country: "australia",
  },
  {
    city: "hannover",
    airport: "hannover-airport",
    airportCode: "HAJ",
    country: "germany",
  },
  {
    city: "hanover",
    airport: "hannover",
    airportCode: "ZVR",
    country: "germany",
  },
  {
    city: "harrisburg-PA",
    airport: "harrisburg-airport",
    airportCode: "MDT",
    country: "usa",
  },
  {
    city: "hartford-CT",
    airport: "bradley-airport",
    airportCode: "BDL",
    country: "usa",
  },
  {
    city: "helsinki",
    airport: "vantaa-airport",
    airportCode: "HEL",
    country: "finland",
  },
  {
    city: "heraklion",
    airport: "heraklion-nikos-kazantzakis-airport",
    airportCode: "HER",
    country: "greece",
  },
  {
    city: "ho-chi-minh-city",
    airport: "tan-son-nhat-airport",
    airportCode: "SGN",
    country: "vietnam",
  },
  {
    city: "hobart",
    airport: "hobart-airport",
    airportCode: "HBA",
    country: "australia",
  },
  {
    city: "hong-kong",
    airport: "hong-kong-airport",
    airportCode: "HKG",
    country: "china",
  },
  {
    city: "houston-TX",
    airport: "george-bush-airport",
    airportCode: "IAH",
    country: "usa",
  },
  {
    city: "Hurghada",
    airport: "hurghada-airport",
    airportCode: "HRG",
    country: "egypt",
  },
  {
    city: "hyannis-MA",
    airport: "barnstable-municipal-airport",
    airportCode: "HYA",
    country: "usa",
  },
  {
    city: "hyderabad",
    airport: "rajiv-gandhi-airport",
    airportCode: "HYD",
    country: "india",
  },
  {
    city: "ibiza",
    airport: "ibiza-airport",
    airportCode: "IBZ",
    country: "spain",
  },
  {
    city: "indianapolis-IN",
    airport: "indianapolis-airport",
    airportCode: "IND",
    country: "usa",
  },
  {
    city: "indore",
    airport: "devi-ahilyabai-holkar-airport",
    airportCode: "IDR",
    country: "india",
  },
  {
    city: "ioannina",
    airport: "ioannina-airport",
    airportCode: "IOA",
    country: "greece",
  },
  {
    city: "islamabad",
    airport: "new-islamabad-airport",
    airportCode: "ISB",
    country: "pakistan",
  },
  {
    city: "istanbul-sabiha-gokcen",
    airport: "sabiha-gokcen-airport",
    airportCode: "SAW",
    country: "turkey",
  },
  {
    city: "istanbul",
    airport: "istanbul-airport",
    airportCode: "IST",
    country: "turkey",
  },
  {
    city: "jacksonville-FL",
    airport: "jacksonville-airport",
    airportCode: "JAX",
    country: "usa",
  },
  {
    city: "jaipur",
    airport: "jaipur-airport",
    airportCode: "JAI",
    country: "india",
  },
  {
    city: "jakarta",
    airport: "soekarno-hatta-airport",
    airportCode: "CGK",
    country: "indonesia",
  },
  {
    city: "jeddah",
    airport: "king-abdulaziz-airport",
    airportCode: "JED",
    country: "saudi-arabia",
  },
  {
    city: "johannesburg",
    airport: "O-R-tambo-airport",
    airportCode: "JNB",
    country: "south-africa",
  },
  {
    city: "johor-bahru",
    airport: "sultan-ismail-airport",
    airportCode: "JHB",
    country: "malaysia",
  },
  {
    city: "kagoshima",
    airport: "kagoshima-airport",
    airportCode: "KOJ",
    country: "japan",
  },
  {
    city: "kingston",
    airport: "kingston-norman-manly",
    airportCode: "KIN",
    country: "jamaica",
  },
  {
    city: "kalamazoo-MI",
    airport: "battle-creek-airport",
    airportCode: "AZO",
    country: "usa",
  },
  {
    city: "kalgoorlie",
    airport: "boulder-airport",
    airportCode: "KGI",
    country: "australia",
  },
  {
    city: "kansas-city-MO",
    airport: "kansas-city-airport",
    airportCode: "MCI",
    country: "usa",
  },
  {
    city: "kaohsiung",
    airport: "kaohsiung-airport",
    airportCode: "KHH",
    country: "china",
  },
  {
    city: "karachi",
    airport: "jinnah-airport",
    airportCode: "KHI",
    country: "pakistan",
  },
  {
    city: "karmoy",
    airport: "haugesund-airport",
    airportCode: "HAU",
    country: "norway",
  },
  {
    city: "kathmandu",
    airport: "tribhuvan-airport",
    airportCode: "KTM",
    country: "nepal",
  },
  {
    city: "kavala",
    airport: "alexander-the-great-airport",
    airportCode: "KVA",
    country: "greece",
  },
  {
    city: "kelowna",
    airport: "kelowna-airport",
    airportCode: "YLW",
    country: "canada",
  },
  {
    city: "kigali",
    airport: "kigali-airport",
    airportCode: "KGL",
    country: "rwanda",
  },
  {
    city: "knoxville-TN",
    airport: "mcghee-tyson-airport",
    airportCode: "TYS",
    country: "usa",
  },
  {
    city: "kochi",
    airport: "cochin-airport",
    airportCode: "COK",
    country: "india",
  },
  {
    city: "koh-samui",
    airport: "ko-samui-airport",
    airportCode: "USM",
    country: "thailand",
  },
  {
    city: "kolkata",
    airport: "netaji-subhas-chandra-bose-airport",
    airportCode: "CCU",
    country: "india",
  },
  {
    city: "kos",
    airport: "ippokratis-airport",
    airportCode: "KGS",
    country: "greece",
  },
  {
    city: "kota-bharu",
    airport: "sultan-ismail-petra-airport",
    airportCode: "KBR",
    country: "malaysia",
  },
  {
    city: "kota-kinabalu",
    airport: "kota-kinabalu-airport",
    airportCode: "BKI",
    country: "malaysia",
  },
  {
    city: "kozhikode",
    airport: "calicut-airport",
    airportCode: "CCJ",
    country: "india",
  },
  {
    city: "krabi",
    airport: "krabi-airport",
    airportCode: "KBV",
    country: "thailand",
  },
  {
    city: "krakow",
    airport: "john-paul-II-balice-airport",
    airportCode: "KRK",
    country: "poland",
  },
  {
    city: "kristiansand",
    airport: "kjevik-airport",
    airportCode: "KRS",
    country: "norway",
  },
  {
    city: "kuala-lumpur",
    airport: "kuala-lumpur-airport",
    airportCode: "KUL",
    country: "malaysia",
  },
  {
    city: "kuala-terengganu",
    airport: "sultan-mahmud-airport",
    airportCode: "TGG",
    country: "malaysia",
  },
  {
    city: "kuching",
    airport: "kuching-airport",
    airportCode: "KCH",
    country: "malaysia",
  },
  {
    city: "kuwait",
    airport: "kuwait-airport",
    airportCode: "KWI",
    country: "kuwait",
  },
  {
    city: "la-crosse-WI",
    airport: "la-crosse-airport",
    airportCode: "LSE",
    country: "usa",
  },
  {
    city: "labuan",
    airport: "labuan-airport",
    airportCode: "LBU",
    country: "malaysia",
  },
  {
    city: "lahore",
    airport: "allama-iqbal-airport",
    airportCode: "LHE",
    country: "pakistan",
  },
  {
    city: "london",
    airport: "london-gatwick",
    airportCode: "LGW",
    country: "united-kingdom",
  },
  {
    city: "london-city",
    airport: "london-city-airport",
    airportCode: "LCY",
    country: "united-kingdom",
  },
  {
    city: "lambert-MO",
    airport: "st-louis-airport",
    airportCode: "STL",
    country: "usa",
  },
  {
    city: "lamezia-terme",
    airport: "lamezia-terme-airport",
    airportCode: "SUF",
    country: "italy",
  },
  {
    city: "lampang",
    airport: "lampang-airport",
    airportCode: "LPT",
    country: "thailand",
  },
  {
    city: "lampedusa",
    airport: "lampedusa-airport",
    airportCode: "LMP",
    country: "italy",
  },
  {
    city: "langkawi",
    airport: "langkawi-airport",
    airportCode: "LGK",
    country: "malaysia",
  },
  {
    city: "lanzarote",
    airport: "lanzarote-airport",
    airportCode: "ACE",
    country: "spain",
  },
  {
    city: "lagos",
    airportCode: "LOS",
    airport: "murtala-muhammed-international-airport",
    country: "nigeria"
  },
  {
    city: "larnaca",
    airport: "larnaca-airport",
    airportCode: "LCA",
    country: "cyprus",
  },
  {
    city: "las-vegas-NV",
    airport: "mccarran-airport",
    airportCode: "LAS",
    country: "usa",
  },
  {
    city: "launceston",
    airport: "launceston-airport",
    airportCode: "LST",
    country: "australia",
  },
  {
    city: "le-mans",
    airport: "le-mans-TGV",
    airportCode: "ZLN",
    country: "france",
  },
  {
    city: "lecce",
    airport: "lecce",
    airportCode: "LCZ",
    country: "italy",
  },
  {
    city: "leeds",
    airport: "leeds-bradford-airport",
    airportCode: "LBA",
    country: "united-kingdom",
  },
  {
    city: "leipzig",
    airport: "halle-airport",
    airportCode: "LEJ",
    country: "germany",
  },
  {
    city: "leipzig",
    airport: "leipzig/halle",
    airportCode: "XIT",
    country: "germany",
  },
  {
    city: "lexington-KY",
    airport: "blue-grass-airport ",
    airportCode: "LEX",
    country: "usa",
  },
  {
    city: "lille,",
    airport: "TGV ",
    airportCode: "XDB",
    country: "france",
  },
  {
    city: "lisbon",
    airport: "portela-airport",
    airportCode: "LIS",
    country: "portugal",
  },
  {
    city: "little-rock-AR",
    airport: "adams-field-airport",
    airportCode: "LIT",
    country: "usa",
  },
  {
    city: "lombok",
    airport: "selaparang-airport",
    airportCode: "LOP",
    country: "indonesia",
  },
  {
    city: "london",
    airport: "heathrow-airport",
    airportCode: "LHR",
    country: "united-kingdom",
  },
  {
    city: "los-angeles-CA",
    airport: "los-angeles-airport",
    airportCode: "LAX",
    country: "usa",
  },
  {
    city: "louisville-KY",
    airport: "standiford-field-airport",
    airportCode: "SDF",
    country: "usa",
  },
  {
    city: "lucknow",
    airport: "chaudhary-charan-singh-airport",
    airportCode: "LKO",
    country: "india",
  },
  {
    city: "lulea",
    airport: "lulea-airport",
    airportCode: "LLA",
    country: "sweden",
  },
  {
    city: "",
    airport: "",
    airportCode: "",
    country: "",
  },
  {
    city: "luxembourg",
    airport: "luxembourg-airport",
    airportCode: "LUX",
    country: "luxembourg",
  },
  {
    city: "lisbon",
    airport: "lisbon-airport",
    airportCode: "LIS",
    country: "portugal",
  },
  {
    city: "lyon",
    airport: "part-dieu-TGV",
    airportCode: "XYD",
    country: "france",
  },
  {
    city: "lyon",
    airport: "satolaos-saint-exupery-airport",
    airportCode: "LYS",
    country: "france",
  },
  {
    city: "mackay",
    airport: "mackay-airport",
    airportCode: "MKY",
    country: "australia",
  },
  {
    city: "madeira",
    airport: "funchal-santa-catarina-airport",
    airportCode: "FNC",
    country: "portugal",
  },
  {
    city: "madison-WI",
    airport: "dane-county-airport",
    airportCode: "MSN",
    country: "usa",
  },
  {
    city: "madrid",
    airport: "barajas-adolfo-suarez-airport",
    airportCode: "MAD",
    country: "spain",
  },
  {
    city: "makassar",
    airport: "sultan-hasanuddin-airport",
    airportCode: "UPG",
    country: "indonesia",
  },
  {
    city: "malaga",
    airport: "malaga-airport",
    airportCode: "AGP",
    country: "spain",
  },
  {
    city: "male",
    airport: "velana-airport",
    airportCode: "MLE",
    country: "maldives",
  },
  {
    city: "malta",
    airport: "luqa-airport",
    airportCode: "MLA",
    country: "malta",
  },
  {
    city: "manado",
    airport: "sam-ratulangi-airport",
    airportCode: "MDC",
    country: "indonesia",
  },
  {
    city: "manchester",
    airport: "manchester-airport",
    airportCode: "MAN",
    country: "united-kingdom",
  },
  {
    city: "mangalore",
    airport: "mangalore-airport",
    airportCode: "IXE",
    country: "india",
  },
  {
    city: "manhattan-KS",
    airport: "manhattan-airport",
    airportCode: "MHK",
    country: "usa",
  },
  {
    city: "manila",
    airport: "ninoy-aquino-airport",
    airportCode: "MNL",
    country: "philippines",
  },
  {
    city: "marquette-MI",
    airport: "sawyer-airport",
    airportCode: "MQT",
    country: "usa",
  },
  {
    city: "marrakech",
    airport: "menara-airport",
    airportCode: "RAK",
    country: "morocco",
  },
  {
    city: "marseille",
    airport: "marignane-airport",
    airportCode: "MRS",
    country: "france",
  },
  {
    city: "marseille",
    airport: "st-charles-TGV",
    airportCode: "XRF",
    country: "france",
  },
  {
    city: "martha's-vineyard-MA",
    airport: "martha's-vineyard-airport",
    airportCode: "MVY",
    country: "usa",
  },
  {
    city: "medan",
    airport: "polonia-kualanamu-airport",
    airportCode: "KNO",
    country: "indonesia",
  },
  {
    city: "medellin",
    airport: "jose-maria-cordova-airport",
    airportCode: "MDE",
    country: "colombia",
  },
  {
    city: "medina",
    airport: "madinah-prince-mohammad-airport",
    airportCode: "MED",
    country: "saudi-arabia",
  },
  {
    city: "melbourne",
    airport: "tullamarine-airport",
    airportCode: "MEL",
    country: "australia",
  },
  {
    city: "memphis-TN",
    airport: "memphis-airport",
    airportCode: "MEM",
    country: "usa",
  },
  {
    city: "menorca",
    airport: "menorca-airport",
    airportCode: "MAH",
    country: "spain",
  },
  {
    city: "metz-nancy",
    airport: "lorraine-TGV",
    airportCode: "XZI",
    country: "france",
  },
  {
    city: "miami-FL",
    airport: "miami-airport",
    airportCode: "MIA",
    country: "usa",
  },
  {
    city: "milan",
    airport: "linate-airport",
    airportCode: "LIN",
    country: "italy",
  },
  {
    city: "milan",
    airport: "malpensa-airport",
    airportCode: "MXP",
    country: "italy",
  },
  {
    city: "milan",
    airport: "milano-centrale",
    airportCode: "XIK",
    country: "italy",
  },
  {
    city: "milan",
    airport: "rogoredo",
    airportCode: "IMR",
    country: "italy",
  },
  {
    city: "milwaukee-WI",
    airport: "general-mitchell-airport",
    airportCode: "MKE",
    country: "usa",
  },
  {
    city: "minneapolis-MN",
    airport: "saint-paul-airport",
    airportCode: "MSP",
    country: "usa",
  },
  {
    city: "okinawa",
    airport: "naha-airport",
    airportCode: "OKA",
    country: "japan",
  },
  {
    city: "oklahoma-city-OK",
    airport: "will-rogers-world-airport",
    airportCode: "OKC",
    country: "usa",
  },
  {
    city: "omaha-NE",
    airport: "eppley-airfield",
    airportCode: "OMA",
    country: "usa",
  },
  {
    city: "orlando-FL",
    airport: "orlando-airport",
    airportCode: "MCO",
    country: "usa",
  },
  {
    city: "osaka",
    airport: "kansai-airport",
    airportCode: "KIX",
    country: "japan",
  },
  {
    city: "oslo",
    airport: "gardermoen-airport",
    airportCode: "OSL",
    country: "norway",
  },
  {
    city: "oslo",
    airport: "torp-sandefjord-airport",
    airportCode: "TRF",
    country: "norway",
  },
  {
    city: "ostersund",
    airport: "are-ostersund-airport",
    airportCode: "OSD",
    country: "sweden",
  },
  {
    city: "ottawa",
    airport: "macdonald-cartier-airport",
    airportCode: "YOW",
    country: "canada",
  },
  {
    city: "padang",
    airport: "minangkabau-airport",
    airportCode: "PDG",
    country: "indonesia",
  },
  {
    city: "palembang",
    airport: "sultan-mahmud-badaruddin-airport",
    airportCode: "PLM",
    country: "indonesia",
  },
  {
    city: "palermo",
    airport: "punta-raisi-airport",
    airportCode: "PMO",
    country: "italy",
  },
  {
    city: "palma-mallorca",
    airport: "palma-de-mallorca-airport",
    airportCode: "PMI",
    country: "spain",
  },
  {
    city: "panteleria",
    airport: "panteleria-airport",
    airportCode: "PNL",
    country: "italy",
  },
  {
    city: "paris",
    airport: "charles-de-gaulle-airport",
    airportCode: "CDG",
    country: "france",
  },
  {
    city: "patna",
    airport: "jay-prakash-narayan-airport",
    airportCode: "PAT",
    country: "india",
  },
  {
    city: "pekanbaru",
    airport: "sultan-syarif-kasim-airport",
    airportCode: "PKU",
    country: "indonesia",
  },
  {
    city: "penang",
    airport: "penang-airport",
    airportCode: "PEN",
    country: "malaysia",
  },
  {
    city: "peoria-IL",
    airport: "greater-peoria-airport",
    airportCode: "PIA",
    country: "usa",
  },
  {
    city: "perth",
    airport: "perth-airport",
    airportCode: "PER",
    country: "australia",
  },
  {
    city: "peshawar",
    airport: "bacha-khan-airport",
    airportCode: "PEW",
    country: "pakistan",
  },
  {
    city: "philadelphia-PA",
    airport: "philadelphia-airport",
    airportCode: "PHL",
    country: "usa",
  },
  {
    city: "phnom-penh",
    airport: "phnom-penh-airport",
    airportCode: "PNH",
    country: "cambodia",
  },
  {
    city: "phoenix-AZ",
    airport: "sky-harbor-airport",
    airportCode: "PHX",
    country: "usa",
  },
  {
    city: "milan",
    airport: "malpensa-airport",
    airportCode: "MXP",
    country: "italy",
  },
  {
    city: "minsk",
    airport: "minsk-2-national-airport",
    airportCode: "MSQ",
    country: "belarus",
  },
  {
    city: "miri",
    airport: "mir-airport",
    airportCode: "MYY",
    country: "malaysia",
  },
  {
    city: "moline-IL",
    airport: "quad-city-airport",
    airportCode: "MLI",
    country: "usa",
  },
  {
    city: "montpellier",
    airport: "st-roch-TGV",
    airportCode: "XPJ",
    country: "france",
  },
  {
    city: "montreal",
    airport: "pierre-elliott-trudeau-airport",
    airportCode: "YUL",
    country: "canada",
  },
  {
    city: "moscow",
    airport: "sheremetyevo-airport",
    airportCode: "SVO",
    country: "russia",
  },
  {
    city: "mount-isa",
    airport: "mount-isa-airport",
    airportCode: "ISA",
    country: "australia",
  },
  {
    city: "multan",
    airport: "multan-airport",
    airportCode: "MUX",
    country: "pakistan",
  },
  {
    city: "mumbai",
    airport: "bombay-chhatrapati-shivaji-maharaj-airport",
    airportCode: "BOM",
    country: "india",
  },
  {
    city: "munich",
    airport: "franz-josef-strauss-airport",
    airportCode: "MUC",
    country: "germany",
  },
  {
    city: "munich",
    airport: "munich",
    airportCode: "ZMU",
    country: "germany",
  },
  {
    city: "muscat",
    airport: "muscat-airport",
    airportCode: "MCT",
    country: "oman",
  },
  {
    city: "mykonos",
    airport: "mykonos-airport",
    airportCode: "JMK",
    country: "greece",
  },
  {
    city: "mytilene",
    airport: "mytilene-airport",
    airportCode: "MJT",
    country: "greece",
  },
  {
    city: "n'djamena",
    airport: "n'djamena-airport",
    airportCode: "NDJ",
    country: "chad",
  },
  {
    city: "nadi",
    airport: "nadi-airport",
    airportCode: "NAN",
    country: "fiji",
  },
  {
    city: "nagoya",
    airport: "chubu-centrair-airport",
    airportCode: "NGO",
    country: "japan",
  },
  {
    city: "nagpur",
    airport: "dr-babasaheb-ambedkar-airport",
    airportCode: "NAG",
    country: "india",
  },
  {
    city: "nairobi",
    airport: "jomo-kenyatta-airport",
    airportCode: "NBO",
    country: "kenya",
  },
  {
    city: "nantes",
    airport: "TGV",
    airportCode: "QJZ",
    country: "france",
  },
  {
    city: "nantucket-MA",
    airport: "nantucket-memoria-airport",
    airportCode: "ACK",
    country: "usa",
  },
  {
    city: "napoli",
    airport: "capodichino-airport",
    airportCode: "NAP",
    country: "italy",
  },
  {
    city: "napoli",
    airport: "naples-central",
    airportCode: "INP",
    country: "italy",
  },
  {
    city: "nashville-TN",
    airport: "nashville-airport",
    airportCode: "BNA",
    country: "usa",
  },
  {
    city: "new-delhi",
    airport: "indira-gandhi-airport",
    airportCode: "DEL",
    country: "india",
  },
  {
    city: "new-orleans-LA",
    airport: "louis-armstrong-airport",
    airportCode: "MSY",
    country: "usa",
  },
  {
    city: "new-york-NY",
    airport: "john-F-kennedy-airport",
    airportCode: "JFK",
    country: "usa",
  },
  {
    city: "new-york-NY",
    airport: "la-guardia-airport",
    airportCode: "LGA",
    country: "usa",
  },
  {
    city: "new-york-NY",
    airport: "newark-liberty-airport",
    airportCode: "EWR",
    country: "usa",
  },
  {
    city: "newcastle",
    airport: "newcastle-airport",
    airportCode: "NCL",
    country: "united-kingdom",
  },
  {
    city: "newcastle",
    airport: "williamtown-airport",
    airportCode: "NTL",
    country: "australia",
  },
  {
    city: "nice",
    airport: "cote-D-azur-airport",
    airportCode: "NCE",
    country: "france",
  },
  {
    city: "niigata",
    airport: "niigata-airport",
    airportCode: "KIJ",
    country: "japan",
  },
  {
    city: "nimes",
    airport: "nimes-TGV",
    airportCode: "ZYN",
    country: "france",
  },
  {
    city: "norfolk-VA",
    airport: "norfolk-airport",
    airportCode: "ORF",
    country: "usa",
  },
  {
    city: "noumea",
    airport: "la-tontouta-airport",
    airportCode: "NOU",
    country: "new-caledonia",
  },
  {
    city: "nuremberg",
    airport: "nuernberg",
    airportCode: "ZAQ",
    country: "germany",
  },
  {
    city: "nuremberg",
    airport: "nuremberg-airport",
    airportCode: "NUE",
    country: "germany",
  },
  {
    city: "odesa",
    airport: "odesa-airport",
    airportCode: "ODS",
    country: "ukraine",
  },
  {
    city: "okinawa",
    airport: "naha-airport",
    airportCode: "OKA",
    country: "japan",
  },
  {
    city: "phuket",
    airport: "phuket-airport",
    airportCode: "HKT",
    country: "thailand",
  },
  {
    city: "pittsburgh-PA",
    airport: "pittsburgh-airport",
    airportCode: "PIT",
    country: "usa",
  },
  {
    city: "poitiers",
    airport: "poitiers-TGV",
    airportCode: "XOP",
    country: "france",
  },
  {
    city: "ponta-delgada",
    airport: "joao-paulo-II-airport",
    airportCode: "PDL",
    country: "portugal",
  },
  {
    city: "port-elizabeth",
    airport: "port-elizabeth-airport",
    airportCode: "PLZ",
    country: "south-africa",
  },
  {
    city: "portland-ME",
    airport: "portland-jetport",
    airportCode: "PWM",
    country: "usa",
  },
  {
    city: "porto",
    airport: "francisco-sa-carneiro-airport",
    airportCode: "OPO",
    country: "portugal",
  },
  {
    city: "prague",
    airport: "vaclav-havel-ruzyne-airport",
    airportCode: "PRG",
    country: "czech-republic",
  },
  {
    city: "proserpine",
    airport: "whitsunday-coast-airport",
    airportCode: "PPP",
    country: "australia",
  },
  {
    city: "pula",
    airport: "pula-airport",
    airportCode: "PUY",
    country: "croatia",
  },
  {
    city: "pune",
    airport: "lohegaon-airport",
    airportCode: "PNQ",
    country: "india",
  },
  {
    city: "qingdao",
    airport: "liuting-airport",
    airportCode: "TAO",
    country: "china",
  },
  {
    city: "queenstown",
    airport: "queenstown-airport",
    airportCode: "ZQN",
    country: "new-zealand",
  },
  {
    city: "rail-n-fly",
    airport: "DB-german-railway-service",
    airportCode: "QYG",
    country: "germany",
  },
  {
    city: "raipur",
    airport: "swami-vivekananda-airport",
    airportCode: "RPR",
    country: "india",
  },
  {
    city: "raleigh-durham-NC",
    airport: "durham-airport",
    airportCode: "RDU",
    country: "usa",
  },
  {
    city: "ranchi",
    airport: "birsa-munda-airport",
    airportCode: "IXR",
    country: "india",
  },
  {
    city: "regina",
    airport: "regina-airport",
    airportCode: "YQR",
    country: "canada",
  },
  {
    city: "reims",
    airport: "champagne-ardenne-TGV",
    airportCode: "XIZ",
    country: "france",
  },
  {
    city: "rennes",
    airport: "rennes-TGV",
    airportCode: "ZFJ",
    country: "france",
  },
  {
    city: "reno-NV",
    airport: "tahoe-airpor",
    airportCode: "RNO",
    country: "usa",
  },
  {
    city: "reykjavik",
    airport: "keflavik-airport",
    airportCode: "KEF",
    country: "iceland",
  },
  {
    city: "rhodes",
    airport: "diagoras-airport",
    airportCode: "RHO",
    country: "greece",
  },
  {
    city: "richmond-VA",
    airport: "richmond-airport",
    airportCode: "RIC",
    country: "usa",
  },
  {
    city: "riga",
    airport: "riga-airport",
    airportCode: "RIX",
    country: "latvia",
  },
  {
    city: "riyadh",
    airport: "king-khalid-airport",
    airportCode: "RUH",
    country: "saudi-arabia",
  },
  {
    city: "rochester-MN",
    airport: "rochester-airport",
    airportCode: "RST",
    country: "usa",
  },
  {
    city: "rochester-NY",
    airport: "greater-rochester-airport",
    airportCode: "ROC",
    country: "usa",
  },
  {
    city: "rockhampton",
    airport: "rockhampton-airport",
    airportCode: "ROK",
    country: "australia",
  },
  {
    city: "rome",
    airport: "fiumicino-leonardo-da-vinci-airport",
    airportCode: "FCO",
    country: "italy",
  },
  {
    city: "rotorua",
    airport: "rotorua-airport",
    airportCode: "ROT",
    country: "new-zealand",
  },
  {
    city: "salalah",
    airport: "salalah-airport",
    airportCode: "SLL",
    country: "Oman",
  },
  {
    city: "salt-lake-city-UT",
    airport: "salt-lake-city-airport",
    airportCode: "SLC",
    country: "usa",
  },
  {
    city: "samos",
    airport: "samos-airport",
    airportCode: "SMI",
    country: "greece",
  },
  {
    city: "san-antonio-TX",
    airport: "san-antonio-airport",
    airportCode: "SAT",
    country: "usa",
  },
  {
    city: "san-diego-CA",
    airport: "lindbergh-field-airport",
    airportCode: "SAN",
    country: "usa",
  },
  {
    city: "san-francisco-CA",
    airport: "san-francisco-airport",
    airportCode: "SFO",
    country: "usa",
  },
  {
    city: "san-jose-CA",
    airport: "norman-Y-mineta-airport",
    airportCode: "SJC",
    country: "usa",
  },
  {
    city: "sandakan",
    airport: "sandakan-airport",
    airportCode: "SDK",
    country: "malaysia",
  },
  {
    city: "santorini",
    airport: "santorini-airport",
    airportCode: "JTR",
    country: "greece",
  },
  {
    city: "sapporo",
    airport: "chitose-airport",
    airportCode: "CTS",
    country: "japan",
  },
  {
    city: "sarajevo",
    airport: "butmir-airport",
    airportCode: "SJJ",
    country: "bosnia-herzegovina",
  },
  {
    city: "sarasota-FL",
    airport: "bradenton-airport",
    airportCode: "SRQ",
    country: "usa",
  },
  {
    city: "saskatoon",
    airport: "JG-diefenbaker-airport",
    airportCode: "YXE",
    country: "canada",
  },
  {
    city: "savannah-GA",
    airport: "hilton-head-airport",
    airportCode: "SAV",
    country: "usa",
  },
  {
    city: "seattle-WA",
    airport: "tacoma-airport",
    airportCode: "SEA",
    country: "usa",
  },
  {
    city: "semarang",
    airport: "achmad-yani-airport",
    airportCode: "SRG",
    country: "indonesia",
  },
  {
    city: "sendai",
    airport: "sendai-airport",
    airportCode: "SDJ",
    country: "japan",
  },
  {
    city: "seoul",
    airport: "incheon-airport",
    airportCode: "ICN",
    country: "south-korea",
  },
  {
    city: "seville",
    airport: "seville-airport",
    airportCode: "SVQ",
    country: "spain",
  },
  {
    city: "seychelles",
    airport: "mahe-airport",
    airportCode: "SEZ",
    country: "seychelles",
  },
  {
    city: "shanghai",
    airport: "pudong-airport",
    airportCode: "PVG",
    country: "china",
  },
  {
    city: "shannon",
    airport: "shannon-airport",
    airportCode: "SNN",
    country: "ireland",
  },
  {
    city: "sharm-el-sheikh",
    airport: "sharm-el-sheikh-airport",
    airportCode: "SSH",
    country: "egypt",
  },
  {
    city: "shenyang",
    airport: "taoxian-airport",
    airportCode: "SHE",
    country: "china",
  },
  {
    city: "shenzhen",
    airport: "bao'an-airport",
    airportCode: "SZX",
    country: "china",
  },
  {
    city: "siem-reap",
    airport: "angkor-airport",
    airportCode: "REP",
    country: "cambodia",
  },
  {
    city: "simferopol",
    airport: "simferopol-airport",
    airportCode: "SIP",
    country: "ukraine",
  },
  {
    city: "singapore",
    airport: "changi-airport",
    airportCode: "SIN",
    country: "singapore",
  },
  {
    city: "sioux-city-IA",
    airport: "sioux-gateway-airport",
    airportCode: "SUX",
    country: "usa",
  },
  {
    city: "sioux-falls-SD",
    airport: "joe-foss-field-airport",
    airportCode: "FSD",
    country: "usa",
  },
  {
    city: "sofia",
    airport: "sofia-airport",
    airportCode: "SOF",
    country: "bulgaria",
  },
  {
    city: "sohag",
    airport: "sohag-airport",
    airportCode: "HMB",
    country: "egypt",
  },
  {
    city: "solo",
    airport: "adi-sumarmo-wiryokusumo-airport",
    airportCode: "SOC",
    country: "indonesia",
  },
  {
    city: "venice",
    airport: "marco-polo-airport",
    airportCode: "VCE",
    country: "italy",
  },
  {
    city: "venice",
    airport: "venice-mestre",
    airportCode: "XVY",
    country: "italy",
  },
  {
    city: "verona",
    airport: "villafranca-airport",
    airportCode: "VRN",
    country: "italy",
  },
  {
    city: "victoria",
    airport: "victoria-airport",
    airportCode: "YYJ",
    country: "canada",
  },
  {
    city: "vienna",
    airport: "schwechat-airport",
    airportCode: "VIE",
    country: "austria",
  },
  {
    city: "vigo",
    airport: "vigo-airport",
    airportCode: "VGO",
    country: "spain",
  },
  {
    city: "vijayawada",
    airport: "vijayawada-airport",
    airportCode: "VGA",
    country: "india",
  },
  {
    city: "vilnius",
    airport: "vilnius-airport",
    airportCode: "VNO",
    country: "lithuania",
  },
  {
    city: "vishakhapatnam",
    airport: "visakhapatnam-airport",
    airportCode: "VTZ",
    country: "india",
  },
  {
    city: "warsaw",
    airport: "chopin-okecie-airport",
    airportCode: "WAW",
    country: "poland",
  },
  {
    city: "washington-DC",
    airport: "dulles-airport",
    airportCode: "IAD",
    country: "usa",
  },
  {
    city: "washington-DC",
    airport: "ronald-reagan-national-airport",
    airportCode: "DCA",
    country: "usa",
  },
  {
    city: "waterloo-IA",
    airport: "waterloo-airport",
    airportCode: "ALO",
    country: "usa",
  },
  {
    city: "wellington",
    airport: "wellington-airport",
    airportCode: "WLG",
    country: "new-zealand",
  },
  {
    city: "west-palm-beach-FL",
    airport: "palm-beach-airport",
    airportCode: "PBI",
    country: "usa",
  },
  {
    city: "westchester-county-NY",
    airport: "westchester-county-airport",
    airportCode: "HPN",
    country: "usa",
  },
  {
    city: "wichita-KS",
    airport: "mid-continent-airport",
    airportCode: "ICT",
    country: "usa",
  },
  {
    city: "winnipeg",
    airport: "winnipeg-airport",
    airportCode: "YWG",
    country: "canada",
  },
  {
    city: "wroclaw",
    airport: "nicolaus-copernicus-airport",
    airportCode: "WRO",
    country: "poland",
  },
  {
    city: "xian",
    airport: "xianyang-airport",
    airportCode: "XIY",
    country: "china",
  },
  {
    city: "yerevan",
    airport: "zvartnots-airport",
    airportCode: "EVN",
    country: "armenia",
  },
  {
    city: "zagreb",
    airport: "pleso-airport",
    airportCode: "ZAG",
    country: "Croatia",
  },
  {
    city: "zanzibar",
    airport: "zanzibar-airport",
    airportCode: "ZNZ",
    country: "tanzania",
  },
  {
    city: "zurich",
    airport: "zurich-airport",
    airportCode: "ZRH",
    country: "switzerland",
  },
  {
    city: "split",
    airport: "split-airport",
    airportCode: "SPU",
    country: "croatia",
  },
  {
    city: "springfield-MO",
    airport: "branson-airport",
    airportCode: "SGF",
    country: "usa",
  },
  {
    city: "srinagar",
    airport: "srinagar-airport",
    airportCode: "SXR",
    country: "india",
  },
  {
    city: "st-john's",
    airport: "st-john's-airport",
    airportCode: "YYT",
    country: "canada",
  },
  {
    city: "stavanger",
    airport: "sola-airport",
    airportCode: "SVG",
    country: "norway",
  },
  {
    city: "stockholm",
    airport: "arlanda-airport",
    airportCode: "ARN",
    country: "sweden",
  },
  {
    city: "stockholm",
    airport: "bromma-airport",
    airportCode: "BMA",
    country: "sweden",
  },
  {
    city: "strasbourg",
    airport: "TGV",
    airportCode: "XWG",
    country: "france",
  },
  {
    city: "stuttgart",
    airport: "stuttgart",
    airportCode: "ZWS",
    country: "germany",
  },
  {
    city: "sukhothai",
    airport: "sukhothai-airport",
    airportCode: "THS",
    country: "thailand",
  },
  {
    city: "sundsvall",
    airport: "timra-airport",
    airportCode: "SDL",
    country: "sweden",
  },
  {
    city: "sunshine-coast",
    airport: "maroochydore-airport",
    airportCode: "MCY",
    country: "australia",
  },
  {
    city: "surabaya",
    airport: "juanda-airport",
    airportCode: "SUB",
    country: "indonesia",
  },
  {
    city: "sydney",
    airport: "kingsford-smith-airport",
    airportCode: "SYD",
    country: "australia",
  },  {
    city: "london",
    airport: "stansted-airport",
    airportCode: "STN",
    country: "united-kingdom",
  },
  {
    city: "syracuse-NY",
    airport: "clarence-E-hancock-airport",
    airportCode: "SYR",
    country: "usa",
  },
  {
    city: "taichung",
    airport: "taichung-airport",
    airportCode: "RMQ",
    country: "china",
  },
  {
    city: "taipei",
    airport: "taoyuan-airport",
    airportCode: "TPE",
    country: "china",
  },
  {
    city: "tallinn",
    airport: "lennart-meri-airport",
    airportCode: "TLL",
    country: "estonia",
  },
  {
    city: "tampa-FL",
    airport: "tampa-airport",
    airportCode: "TPA",
    country: "usa",
  },
  {
    city: "tangier",
    airport: "boukhalef-ibn-battouta-airport",
    airportCode: "TNG",
    country: "morocco",
  },
  {
    city: "tawau",
    airport: "tawau-airport",
    airportCode: "TWU",
    country: "malaysia",
  },
  {
    city: "tbilisi",
    airport: "tbilisi-airport ",
    airportCode: "TBS",
    country: "georgia",
  },
  {
    city: "tel-aviv",
    airport: "ben-gurion-airport",
    airportCode: "TLV",
    country: "israel",
  },
  {
    city: "tenerife",
    airport: "tenerife-north-airport",
    airportCode: "TFN",
    country: "spain",
  },
  {
    city: "thessaloniki",
    airport: "makedonia-airport",
    airportCode: "SKG",
    country: "greece",
  },
  {
    city: "thiruvananthapuram",
    airport: "trivandrum-airport",
    airportCode: "TRV",
    country: "india",
  },
  {
    city: "thunder-bay",
    airport: "thunder-bay-airport",
    airportCode: "YQT",
    country: "canada",
  },
  {
    city: "tirana",
    airport: "rinas-mother-teresa-airport",
    airportCode: "TIA",
    country: "albania",
  },
  {
    city: "tokyo",
    airport: "narita-airport",
    airportCode: "NRT",
    country: "japan",
  },
  {
    city: "toledo-OH",
    airport: "express-airport",
    airportCode: "TOL",
    country: "usa",
  },
  {
    city: "toronto",
    airport: "pearson-airport",
    airportCode: "YYZ",
    country: "canada",
  },
  {
    city: "toulouse",
    airport: "blagnac-airport",
    airportCode: "TLS",
    country: "france",
  },
  {
    city: "tours",
    airport: "saint-pierre-des-corps",
    airportCode: "XSH",
    country: "france",
  },
  {
    city: "townsville",
    airport: "townsville-airport",
    airportCode: "TSV",
    country: "australia",
  },
  {
    city: "trat",
    airport: "trat-airport",
    airportCode: "TDX",
    country: "thailand",
  },
  {
    city: "traverse-city-MI",
    airport: "cherry-capital-airport",
    airportCode: "TVC",
    country: "usa",
  },
  {
    city: "trieste",
    airport: "ronchi-dei-legionari-airport",
    airportCode: "TRS",
    country: "italy",
  },
  {
    city: "trondheim",
    airport: "vaernes-airport",
    airportCode: "TRD",
    country: "norway",
  },
  {
    city: "tucson-AZ",
    airport: "tucson-airport",
    airportCode: "TUS",
    country: "usa",
  },
  {
    city: "tulsa-OK",
    airport: "tulsa-airport",
    airportCode: "TUL",
    country: "usa",
  },
  {
    city: "tunis",
    airport: "carthage-airport",
    airportCode: "TUN",
    country: "tunisia",
  },
  {
    city: "turin",
    airport: "caselle-airport",
    airportCode: "TRN",
    country: "italy",
  },
  {
    city: "udaipur",
    airport: "maharana-pratap-airport",
    airportCode: "UDR",
    country: "india",
  },
  {
    city: "umea",
    airport: "umea-airport",
    airportCode: "UME",
    country: "sweden",
  },
  {
    city: "vadodara",
    airport: "vadodara-airport",
    airportCode: "BDQ",
    country: "india",
  },
  {
    city: "valence",
    airport: "valence-TGV",
    airportCode: "XHK",
    country: "france",
  },
  {
    city: "valencia",
    airport: "valencia-airport",
    airportCode: "VLC",
    country: "spain",
  },
  {
    city: "vancouver",
    airport: "vancouver-airport",
    airportCode: "YVR",
    country: "canada",
  },
  {
    city: "varanasi",
    airport: "lal-bahadur-shastri-airport",
    airportCode: "VNS",
    country: "india",
  },
  {
    city: "bangkok",
    airportCode: "BKK",
    airport: "suvarnabhumi-international-airport",
    country: "thailand"
  },
  {
    city: "khartoum",
    airportCode: "KRT",
    airport: "khartoum-international-airport",
    country: "sudan"
  },
  {
    city: "jeddah",
    airportCode: "JED",
    airport: "king-abdulaziz-international-airport",
    country: "saudi-arab"
  },
  {
    city: "riyadh",
    airportCode: "RUH",
    airport: "king-khalid-international-airport",
    country: "saudi-arab"
  },
  {
    city: "medina",
    airportCode: "MED",
    airport: "prince-mohammed-bin-abdulaziz-international-airport",
    country: "saudi-arab"
  },
  {
    city: "kigali",
    airportCode: "KGL",
    airport: "kigali-international-airport",
    country: 'rwanda'
  },
  {
    city: "dakar",
    airportCode: "DSS",
    airport: "blaise-diagne-international-airport",
    country: 'senegal'
  },
  {
    city: "dakar",
    airportCode: "DKR",
    airport: "léopold-sédar-senghor-international-airport",
    country: 'senegal'
  },
  {
    city: "manila",
    airportCode: "MNL",
    airport: "ninoy-aquino-international-airport",
    country: 'philippines'
  },
  {
    city: "cebu",
    airportCode: "CEB",
    airport: "mactan-cebu-international-airport",
    country: 'philippines'
  },
  {
    city: "Davao",
    airportCode: "DVO",
    airport: "davao-international-airport",
    country: 'philippines'
  },
  {
    city: "Davao",
    airportCode: "DVO",
    airport: "francisco-bangoy-international-airport",
    country: 'philippines'
  },
  {
    city: "zimbabwe",
    airportCode: "HRE",
    airport: "robert-gabriel-mugabe-international-airport",
    country: 'zimbabwe'
  },
  {
    city: "oman",
    airportCode: "MCT",
    airport: "muscat-international-airport",
    country: 'uae',
  },
  {
    city: "muharraq",
    airportCode: "BAH",
    airport: "bahrain-international-airport",
    country: 'uae',
  },
  {
    city: "montego-bay",
    airportCode: "MBJ",
    airport: "sangster-international-airport-montego-bay",
    country: 'jamaica',
  },
  {
    city: "ottawa",
    airportCode: "YOW",
    airport: "ottawa-international-airport",
    country: 'canada',
  },
  {
    city: "british-columbia",
    airportCode: "YVR",
    airport: "vancouver-international-airport",
    country: 'canada',
  },
  {
    city: "quebec",
    airportCode: "YUL",
    airport: "montréal-pierre-elliott-trudeau-international-airport",
    country: 'canada',
  },
  {
    city: "st-george-town",
    airportCode: "GND",
    airport: "maurice-bishop-international-airport",
    country: 'grenada',
  },
  {
    city: "banjul",
    airportCode: "BJL",
    airport: "banjul-international-airport",
    country: 'gambia',
  },
  {
    city: "punta-caucedo",
    airportCode: "SDQ",
    airport: "santo-domingo/las-américas-international-airport",
    country: 'dominican-republic',
  },
  {
    city: "punta-cana",
    airportCode: "PUJ",
    airport: "punta-cana-international-airport",
    country: 'dominican-republic',
  },
  {
    city: "marigot",
    airportCode: "DOM",
    airport: "douglas-charles-international-airport",
    country: 'dominica',
  },
  {
    city: "canefield",
    airportCode: "DCF",
    airport: "roseau-canefield-international-airport",
    country: 'dominica',
  },
  {
    city: "chennai",
    airportCode: "MAA",
    airport: "chennai-international-airport",
    country: 'india',
  },
  {
    city: "mumbai",
    airportCode: "BOM",
    airport: "chhatrapati-shivaji-maharaj-international-airport",
    country: 'india',
  },
  {
    city: "ahmedabad",
    airportCode: "AMD",
    airport: "sardar-vallabhbhai-patel-international-airport",
    country: 'india',
  },
  {
    city: "goa",
    airportCode: "GOI",
    airport: "goa-international-airport",
    country: 'india',
  },
  {
    city: "cochin",
    airportCode: "COK",
    airport: "cochin-international-airport",
    country: 'india',
  },
  {
    city: "delhi",
    airportCode: "DEL",
    airport: "indira-gandhi-international-airport",
    country: 'india',
  },
  {
    city: "amritsar",
    airportCode: "ATQ",
    airport: "sri-guru-ram-dass-jee-international-airport",
    country: 'india',
  },
  {
    city: "tamil-nadu",
    airportCode: "IXM",
    airport: "madurai-international-airport",
    country: 'india',
  },
  {
    city: "chittagong",
    airportCode: "CGP",
    airport: "shah-amanat-international-airport",
    country: 'bangladesh',
  },
  {
    city: "beijing",
    airportCode: "PEK",
    airport: "beijing-capital-international-airport",
    country: 'china',
  },
  {
    city: "guangzhou",
    airportCode: "CAN",
    airport: "guangzhou-baiyun-international-airport",
    country: 'china',
  },
  {
    city: "shanghai",
    airportCode: "SHA",
    airport: "shanghai-hongqiao-international-airport",
    country: 'china',
  },
  {
    city: "shenzhen",
    airportCode: "SZX",
    airport: "shenzhen-bao-international-airport",
    country: 'china',
  },
  {
    city: "bujumbura",
    airportCode: "BJM",
    airport: "melchior-ndadaye-international-airport",
    country: 'burundi',
  },
  {
    city: "gitega",
    airportCode: "GID",
    airport: "gitega-international-airport",
    country: 'burundi',
  },
  {
    city: "brussels",
    airportCode: "BRU",
    airport: "brussels-international-airport",
    country: 'belgium',
  },
  {
    city: "kinshasa",
    airportCode: "FIH",
    airport: "ndjili-international-airport",
    country: 'democratic-republic-of-the-congo',
  }  
];
