import { InquiryForm } from "components/inquiryForm";
import Layout from "fragments/layout/layout";

export function Contact() {
  return (
    <Layout title="Contact us">
      <InquiryForm />
    </Layout>
  );
}
