import Layout from "fragments/layout/layout";
import React from "react";

export function BookingConditions() {
  return (
    <Layout class="bg-gray-100 p-4">
      <div class="max-w-4xl my-32 mx-auto bg-white p-8 rounded-xl shadow-lg">
        <h1 class="text-3xl font-bold mb-4">Booking Conditions</h1>

        <h2 class="text-xl font-semibold mb-2">Grasping Your Agreement</h2>
        <p class="mb-4">
          Kindly read these Booking Conditions cautiously. You should not make
          any reservation except if you get it and concur with these Booking
          Conditions. It would be ideal for you to save or potentially print a
          duplicate of these Booking Conditions at the time the booking is put.
          We will depend on the power of the individual making the booking to
          follow up for the benefit of some other explorer on the booking and
          that individual will tie all such voyagers to these Booking
          Conditions.
        </p>

        <h2 class="text-xl font-semibold mb-2">Disclaimer</h2>
        <p class="mb-4">
          Disclaimer Having travel protection previously your travel is
          incredibly recommended. Ensure, adaptable travel protection covers you
          from the aggravation of dropped flights, losing your baggage or
          startling injury, and other travel issues.
        </p>

        <h2 class="text-xl font-semibold mb-2">
          Your Rights Under Your Agreement
        </h2>
        <p class="mb-4">
          Your freedoms under your agreement for movement plans will rely upon
          the sort of movement course of action you book with us, which will be
          either a reservation for bundle travel or at least one individual
          travel administration. No matter what sort of course of action you
          book with us, the supplier of your movement administration will have
          its agreements, (for example, aircraft states of carriage, convenience
          or vehicle employ terms lodgings provider terms, or visit coordinator
          agreements). Every single such term and conditions (counting limits of
          risk) apply to these Booking Conditions and, likewise, a reference to
          "these Booking Conditions" will mean the agreements set out thus as
          well as all agreements of any supplier of your movement
          administrations. To the degree of any irregularity between a movement
          specialist co-op's agreements and the agreements set out thus, the
          movement specialist organization's agreements will win, save to the
          degree that any arrangement in the movement specialist organization's
          agreements is considered to be invalid or unenforceable. Your movement
          specialist co-ops will be distinguished on your schedule or travel
          records and their agreements will be accessible straightforwardly on
          their sites or by reaching them. On the other hand, you can ask us for
          duplicates. You should pursue all pertinent travel specialist co-op
          agreements cautiously because for each situation they will apply to
          the arrangement of your movement administrations.
        </p>

        <h2 class="text-xl font-semibold mb-2">
          Package Travel Organized By Us
        </h2>
        <p class="mb-4">
          A bundle is a set-up, a single-value mix of something like two of the
          accompanying parts: (a) transport - (b) convenience - or (c) other
          traveler administrations representing a huge extent of the bundle.
          Bundles cover a time of more than 24 hours or incorporate for the time
          being convenience. Your privileges corresponding to bundles organized
          by us are set out in these Booking Conditions (which are considered to
          consolidate all agreements of each movement specialist organization
          for the different parts of your bundle travel plans). Kindly note that
          you likewise have privileges under the Bundle Travel, Bundle
          Occasions, and Bundle Visits Guidelines 1992, a duplicate of which can
          be gotten to at the accompanying connection:
          legislation.gov.uk/uksi/1992/3288/contents/made ("Bundle Travel
          Guidelines").
        </p>

        <h2 class="text-xl font-semibold mb-2">Plan Changes</h2>
        <p class="mb-4">
          All takeoff and appearance times on your flight ticket are given by
          the carrier and are assessed as they were. They might change because
          of aviation authority limitations, weather patterns, or functional
          prerequisites. We suggest that you contact the aircraft or visit the
          carrier's site to affirm your planned takeoff time 24 hours before
          your flight. You ought to likewise affirm takeoff times for each ahead
          flight. In case of timetable changes, the inability to reconfirm any
          area of your schedule might bring about you expecting to buy another
          flight.
        </p>

        <h2 class="text-xl font-semibold mb-2">When You Change Your Booking</h2>
        <p class="mb-4">
          If, after our affirmation receipt has been given, you wish to change
          your movement courses of action in any capacity, for instance, your
          picked travel dates or convenience, we will do our utmost to roll out
          these improvements yet it may not be imaginable all the time. Any
          solicitation for changes to be made should be recorded as a hard copy
          from the lead-named voyager who made the booking. You will be
          approached to pay our organization charge of £75 per traveler per
          booking (or such other sum determined in your movement reports), in
          addition to any extra travel specialist co-op change or undoing
          expenses. Where we cause any obligation for a movement specialist
          co-op change or retraction expense for any reservation that you
          change, you consent to remunerate us for how much that charge is. You
          ought to know that expenses could expand nearer to the flight date.
          While changing travel game plans we might need to re-book you to
          current passages and rates, which might bring about an expansion in
          the expense of your courses of action, notwithstanding our
          organization charge and the movement specialist co-op's change
          expenses. No date changes are allowed beyond your ticket's legitimacy
          period. 'Least stay' and 'most extreme stay' rules apply to return and
          multi-stop flights. Certain movement game plans (for example advance
          buy tickets and non-adaptable passages) may not be inconsistent after
          a booking has been made and any change could cause a scratch-off
          energy of up to 100 percent of the help cost. Name changes are not
          allowed in any case.
        </p>

        <p class="mb-4">
          If you have bought a return flight, multi-stop, or round-the-world
          flight and you don't monitor an affirmed flight, the carrier will
          enlist you as 'absent' and your tickets on your ensuing flights will
          be dropped by the carrier. If it's not too much trouble, reach us
          quickly if you don't plan to take a look at it for an affirmed
          reservation in these conditions.
        </p>

        <h2 class="text-xl font-semibold mb-2">When You Drop Your Booking</h2>
        <p class="mb-2">
          You might drop your movement game plans whenever, but kindly note that
          specific travel courses of action might be non-refundable. Composed
          notice from the lead-named explorer should be gotten at the branch
          where the booking was made. Since we cause costs in dropping your
          movement plans, you should pay our organization charge of £125 per
          individual per booking (or such other sum determined in your movement
          archives). Notwithstanding our organization charge, you should pay the
          movement specialist co-op's appropriate dropping charges.
        </p>

        <p class="mb-2">
          If you have made a booking with us for your Excursions, the crossing
          out expenses will be still up in the air as per the table underneath,
          aside from where generally shown.
        </p>

        <p class="mb-2">
          Assuming that the justification for your abrogation is covered under
          the particulars of your movement insurance contract, you might have
          the option to recover these charges from your movement safety net
          provider. Discounts might be made accessible to the individual named
          on the booking installment receipt, except if generally concurred and
          recorded as a hard copy by all explorers recorded on a booking.
        </p>

        <p class="mb-2">
          Your discount and cure freedoms as set out in these Booking Conditions
          are dependent upon your privileges under the Bundle Travel Guidelines
          and the Buyer Freedoms Act 2015. Except if generally shown, the
          accompanying scratch-off expenses apply to appointments for your
          Excursions as it were. Days before the flight inside which composed
          notice is gotten by us Retraction charge (% of complete Excursion)
        </p>

        <ul class="mb-4">
          <li>Over 70 days Store as it were</li>
          <li>57-70 days Store + 45% of all out Excursion</li>
          <li>37-56 days Store + half of complete Excursion</li>
          <li>28-36 days Store + 65% of all out Excursion</li>
          <li>15-27 days Store + 90% of complete Excursion</li>
          <li>14 days or less Full reserving esteem</li>
        </ul>

        <h2 class="text-xl font-semibold mb-2">
          When We Change or Cancel Your Booking
        </h2>
        <p class="mb-2">
          Once in a while, a movement specialist co-op may need to make changes
          to your unique booking. We acknowledge no obligation or costs brought
          about that might result from these changes, other than as per the
          Bundle Travel Guidelines. The greater part of these progressions will
          be minor and we will educate you concerning them at the earliest
          conceivable date. We will educate you regarding the transporter
          working each trip on your agenda. Any progressions to the transporter
          after you have accepted your tickets will be advised to you at the
          earliest opportunity and in all cases at registration or the loading
          up door. Kindly note that transporters might be dependent upon future
          developments. Such a change is viewed as a minor change. Different
          instances of minor changes incorporate modification of your flight
          takeoff time by under 12 hours, changes to airplane type, and changes
          of convenience to a practically identical or better quality. No
          remuneration is payable for minor changes.
        </p>

        <p class="mb-2">
          In specific conditions, we or a movement specialist co-op might be
          expected to drop your movement game plans (for instance, if the base
          number of explorers expected for a visit or journey isn't reached or
          because of reasons of power majeure, pandemic, or plague sickness or
          on the other hand on the off chance that you neglect to pay the last
          equilibrium by the due date). In these conditions (except for where
          you have neglected to pay the last equilibrium) you can either have a
          discount of all cash paid or on the other hand, if accessible,
          acknowledge a proposal of elective travel plans of similar norm (we
          will discount any cost distinction on the off chance that the option
          is of a lower esteem).
        </p>

        <p class="mb-2">
          If you have booked a bundle organized by us and it is important to
          drop or roll out significant improvements to your game plans we will,
          notwithstanding the above-mentioned, pay you remuneration as follows:
        </p>

        <ul class="mb-2">
          <li>55-29 days preceding travel: £15.00</li>
          <li>28-15 days preceding travel: £25.00</li>
          <li>14-8 days preceding travel: £35.00</li>
          <li>7-0 days preceding travel: £45.00</li>
        </ul>

        <p class="mb-2">
          If it's not too much trouble, allude to the segment named 'Our
          obligation regarding your reserving' for more data in such a manner.
        </p>

        <p class="mb-4">
          Under EU regulation (EC 261/2004) you have freedoms in conditions to
          discounts or remuneration from your carrier in instances of denied
          loading up, scratch-off or defer to flights. Subtleties of these
          freedoms are distributed at EU air terminals and are likewise
          accessible from your carrier. Nonetheless, repayment by a carrier of
          the expense of a flight that structures a piece of a bundle doesn't
          consequently qualify you for a discount of your whole bundle travel
          plan cost from us.
        </p>

        <h2 class="text-xl font-semibold mb-2">International IDs and visas</h2>
        <p class="mb-2">
          All voyagers should have a substantial visa for any global travel and
          numerous nations expect no less than a half year's legitimacy from the
          date of return. A few nations likewise require a machine-decipherable
          visa as well as unstamped accessible pages. While helping with a
          global travel booking, we will expect that all voyagers on the booking
          have a legitimate identification. If this isn't true, you should tell
          us. You genuinely should guarantee that you have substantial
          identifications, visas, and reemergence grants that meet the
          prerequisites of movement and other government specialists (counting,
          in specific cases, visas for travel focuses). Any fines, punishments,
          installments, deferrals, or uses caused because of such archives not
          gathering the prerequisites of those specialists will be your only
          obligation (but to the degree brought about by shortcomings on our
          part). We acknowledge no liability on the off chance that you can't
          travel since you have not agreed with any identification, visa, or
          movement prerequisites. Assuming that you want data on visa and other
          travel record necessities for your outing, kindly let us know If you
          are an English resident we will give you general data on visa and
          identification necessities that apply to worldwide travel appointments
          you make with us. For all identification holders, our experts can get
          explicit data from an outside visa warning specialist co-op for your
          benefit (assuming you wish, we can help you to acquire visas through
          this outer assistance and charges will apply). We don't warrant the
          exactness of data offered by any outer assistance and acknowledge no
          responsibility for any misfortune or harm which you might experience
          in dependence on it (but to the degree brought about by issue on our
          part). If it's not too much trouble, permit satisfactory chances to
          acquire any such guidance or records.
        </p>

        <p class="mb-2">
          Assuming you are going to Australia, Canada, or the US kindly be
          exhorted that there are mandatory pre-enrollment necessities for their
          visa waiver programs. ESTA visa is obligatory for all USA-bound
          travel. For Canada and Australia, you should have an estimated time of
          arrival visa also to travel to these regions. Assuming you have
          enlisted previously, kindly check your enrollment is legitimate and
          has not slipped by. If it's not too much trouble, note, that you may
          not meet the qualification necessities and might be expected to get a
          visa.
        </p>

        <p class="mb-2">
          If you are heading out to South Africa with an individual younger than
          18 you will be approached to show the kid's full unedited birth
          endorsement. There are extra prerequisites assuming the kid is going
          with just a single parent, with neither natural parent, or
          unaccompanied. The inability to give this data on check-in will bring
          about travelers being denied boarding.
        </p>

        <p class="mb-4">
          It is a prerequisite for some air transporters to give individual
          data, including visa data, to pretty much all travelers on their
          airplanes. The information will be gathered when you register (either
          on the web or at the air terminal) or, in certain conditions, will be
          required when you make your booking.
        </p>

        <h2 class="text-xl font-semibold mb-2">
          Our obligation regarding your booking
        </h2>
        <p class="mb-4">
          In all conditions, we won't be obligated where any disappointment in
          the exhibition or arrangement of your movement game plans is expected
          to: (a) your demonstrations or oversights or the demonstrations or
          exclusions of one more part in your gathering (b) any outsider not
          associated with the arrangement of your movement plans and where the
          disappointment is unforeseeable or undeniable (c) uncommon and
          unforeseeable conditions unchangeable as far as we might be concerned,
          the results of which could never have been stayed away from regardless
          of whether all due care had been practiced or (d) an occasion which we
          or a movement specialist organization, even with all due care,
          couldn't predict or hinder (counting yet not to war, danger of war,
          revolt, common aggravations, modern question, psychological militant
          action and its ramifications, normal or other fiasco (like volcanic
          debris or storms), atomic episode, fire, unfriendly weather patterns
          (genuine or undermined, including snow and haze), shut or blocked air
          terminals or ports, undeniable specialized issues with transport and
          comparable occasions). Our responsibility will in all cases be as per
          as well as in an indistinguishable way to (a) the agreements of the
          movement specialist organizations that give your movement plans
          (which, by making a booking with us, you recognize structure a piece
          of these Booking Conditions) - and (b) any significant global shows,
          for instance, the Montreal Show in regard of movement via air, the
          Athens Show in regard of movement via ocean, the Berne Show in regard
          of movement by rail and the Paris Show in regard of the arrangement of
          convenience which set time limits for warnings/claims and cutoff how
          much pay that can be guaranteed for death, injury, deferral to
          travelers/visitors and misfortune, harm and postponement to gear.
          Duplicates of worldwide shows and pertinent travel specialist co-ops'
          agreements are accessible on the web. On the other hand, you can ask
          us for duplicates either via telephone or coming up.
        </p>

        <h2 class="text-xl font-semibold mb-2">
          Bundle Travel Organized By Us
        </h2>
        <p class="mb-4">
          As per the Bundle Travel Guidelines, on the off chance that we have
          organized a bundle for yourself and the movement game plans are not
          performed with sensible expertise and care we will make those game
          plans or pay you proper pay assuming that this has impacted the
          happiness regarding your outing. Our risk regarding bundles organized
          by us, besides in cases including passing, injury, or sickness, is to
          a limit of twice the expense of your bundle travel game plan. This
          greatest sum may be payable how everything has veered off-track and
          you have not gotten any advantage whatsoever from your bundle travel
          plan. Any sums you get from movement specialist co-ops or travel
          protection will be deducted from any aggregate paid to you as paid by
          us.
        </p>

        <h2 class="text-xl font-semibold mb-2">
          Your Money-related Affirmation
        </h2>
        <p class="font-semibold mb-2">
          Fast Events Ltd T/A Speedy (ATOL # 74904) overseeing general Flights
          and Event packs.
        </p>
        <p class="mb-2">
          Enormous quantities of the flights and flight-extensive travel
          strategies we arrange for the good of you are fiscally defended by the
          ATOL plot. However, ATOL protection has no huge bearing on all
          interminable travel organizations. Assuming no one minds, demand that
          we assert what security could apply to your booking. If you don't get
          an ATOL Verification then the booking will not be ATOL protected. If
          you get an ATOL Confirmation anyway all of the bits of your trip are
          not recorded on it, those parts not recorded will not be ATOL
          obtained. For extra information about money-related security and the
          ATOL Underwriting go to atol.org.uk/ATOLCertificate. Exactly when you
          buy an ATOL protected endurance extensive travel through us you will
          get an ATOL Underwriting. This summarizes what is financially
          protected, where you can get information on how this influences you
          and who to contact, expecting things to end up being awful. If your
          booking (or some part of it) is ATOL obtained, we, or the development
          expert center perceived on your ATOL Confirmation, will give you the
          booking organization and coordinate the development organizations as
          recorded on the ATOL Statement (or a sensible other choice). On
          occasion, where neither we nor the development expert center can do
          such as a result of reasons of obligation, a choice ATOL holder could
          outfit you with the development organizations you have bought or a
          sensible other choice (at no extra cost for you). You agree to
          recognize that in those conditions the choice ATOL holder will play
          out those responsibilities and you agree to pay any money amazing to
          be paid by you under your consent to that elective ATOL holder.
          Regardless, you in like manner agree that on occasion it will not be
          possible to choose a choice ATOL holder, in which case you will be
          equipped to present a defense under the ATOL plan (or your Visa
          underwriter where material).
        </p>

        <p class="mb-4">
          If we, or the development expert center recognized on your ATOL
          statement, can't coordinate or offer the sorts of help recorded (or a
          sensible other choice, through a choice ATOL holder and so on) in
          light of reasons of obligation, the Legitimate chairmen of the Air
          Travel Trust could make a portion to (or give a benefit on) you under
          the ATOL plot. You agree that as a compromise for such a portion or
          help you give out absolutely to those Legitimate executives any cases
          which you have or may have arising out of or interfacing with the
          non-plan of the organizations, including any body of evidence against
          us, the movement organizer (or your Mastercard supporter where
          material). You also agree that any such cases may be re-allocated to
          another body, expecting that the other body has paid the totals you
          have affirmed under the ATOL plot. If you book a group that prohibits
          flights, our ABTA interest suggests that we give money-related
          security to your money. If you book other travel blueprints, similar
          to comfort just, this security doesn't have an effect.
        </p>

        <h2 class="text-xl font-semibold mb-2">Expenses and portion</h2>
        <p class="mb-2">
          All expenses are reliant upon openness and can be taken out or varied
          without notice. Costs are per individual and far-reaching of
          evaluation except for if by and large illustrated. Comfort (expecting
          to be incorporated) relies upon the twin proposition, quad share,
          aside from if by and large shown. Elevated expenses may go inside the
          shown dates. We will confirm the right expense with you at the hour of
          the portion. Costs are not guaranteed until the full portion is made.
          To insist on your development strategies you may be supposed to pay a
          store for each person. All stores are non-refundable and non-versatile
          for changes of cerebrum or withdrawals by you. We will educate you as
          to how much the store and date for a clear portion at the hour of your
          booking. For online arrangements, a full portion is normal at the hour
          of acquisition.
        </p>

        <p class="mb-2">
          If you have made a booking with us from our 'Trips' ranges you will be
          supposed to pay a fee of £100 per individual for economy class flights
          and £200 per individual for premium economy, business, and five-star
          flights. An additional store will be normal for any visit or journey
          or for explicit airfares which are part of your Trip. The harmony for
          your Cycle ought to be paid no later than 10 weeks before departure or
          such earlier time that we prompt you. Assuming no one cares either
          way, truly take a gander at your assertion or receipt to insist on
          whether your development blueprints are from us.
        </p>

        <p class="mb-2">
          Certain airfares and organizations (recollecting a couple of airfares
          and organizations for the Trips) are held at especially relentless
          rates which could require a portion in full at the hour of booking and
          may be non-refundable.
        </p>

        <p class="mb-4">
          We will assemble each obligatory obligation, however, a couple of
          countries could charge additional departure, motel, or various costs
          that ought to be paid locally. We urge wayfarers to hold satisfactory
          close-by money to meet these charges.
        </p>

        <h2 class="text-xl font-semibold mb-2">
          Installment by charge and Visa
        </h2>
        <p class="mb-2">
          We acknowledge the most significant charge and Visas. You approve us
          to charge all sums payable by you corresponding to the administrations
          given to the card assigned by you. On the off chance that installment
          isn't gotten from the backer under any circumstance, you consent to
          pay every one of us sums due promptly on request.
        </p>

        <p class="mb-4">
          Installment by bank move (not relevant to online appointments) If you
          are paying by this strategy you should demand account subtleties from
          your specialist and make the installment something like three work
          days before the genuine due date. You should inform your expert of
          your installment whenever it has been made.
        </p>

        <h2 class="text-xl font-semibold mb-2">
          How would I submit a question?
        </h2>
        <p class="mb-4">
          We plan to furnish you with an astonishing travel insight.
          Nonetheless, if you have an issue during your outing, kindly
          illuminate the pertinent travel specialist co-op (for example your
          hotelier) and your movement expert at the earliest opportunity who
          will try to put things right. On the off chance that your grumbling
          isn't settled locally, kindly follow this up in the span of 28 days of
          your get back by keeping in touch with our Client Administrations
          Division at https://fastholidays.co.uk giving your booking reference
          and any remaining pertinent data. It is firmly prescribed that you
          convey any objection to the movement specialist organization being
          referred to as well as your movement advisor right away. On the off
          chance that you neglect to follow this system, we will have been
          denied the amazing chance to research and amend your grumbling while
          you were voyaging and this might influence your freedoms. Out of
          typical available time, you may likewise contact our crisis help
          division
        </p>
      </div>
    </Layout>
  );
}
