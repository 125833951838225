export const continents = [
  "usa",
  "africa",
  "asia",
  "europe",
  "north-america",
  "south-america",
  "australia",
  "india",
];

export const desiredOrder = [
  "France",
  "Australia",
  "Turkey",
  "Spain",
  "USA",
  "Maldives",
  "Germany",
  "Dubai",
  "Canada",
  "Ghana",
  "Thailand",
];

// sortOrder 10

// updated
export const countries = {
  usa: [
    {
      code: "US",
      name: "usa",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fnewyork.png?alt=media&token=7389073e-394c-4f5e-98b3-bbc7948b1314",
    },
  ],
  africa: [
    {
      code: "GH",
      name: "ghana",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fghana.jpg?alt=media&token=c3bff433-84cc-4fa3-ac4a-6727526e361b",
    },
    {
      code: "ET",
      name: "ethiopia",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fethiopia.jpg?alt=media&token=1350b1fb-823e-4a6c-8242-ed175fbbf123",
    },
    {
      code: "ZA",
      name: "south-africa",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fsouth_africa.jpg?alt=media&token=42c5c811-3841-4571-ac02-3fb5e5c8296d",
    },
    {
      code: "SN",
      name: "senegal",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fsenegal.jpg?alt=media&token=cd8134d5-7865-4625-8d57-3fec049b134a",
    },
    {
      code: "EG",
      name: "egypt",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fegypt.jpg?alt=media&token=47b56b9e-b7e2-420e-ad3f-a54fabba3645",
    },
    {
      code: "UG",
      name: "uganda",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fuganda.jpg?alt=media&token=89f85b5b-aff1-4c9a-a1ee-fe9179d785ff",
    },
    {
      code: "KE",
      name: "kenya",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fkenya.jpg?alt=media&token=bda17472-b25b-4b28-955a-13ef4ab05b92",
    },
    {
      code: "TZ",
      name: "tanzania",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Ftanzania.jpg?alt=media&token=98a47363-a786-4d79-96f8-8100d5af6932",
    },
    {
      code: "NG",
      name: "nigeria",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fnigeria.jpg?alt=media&token=5401f7ce-9b81-4214-841c-d30b4eaa3fef",
    },
    {
      code: "SD",
      name: "sudan",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fsudan.jpg?alt=media&token=1efb364c-3794-46b8-9660-fdba37eaf0b8",
    },
    {
      code: "CD",
      name: "democratic-republic-of-the-congo",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fcongo.jpg?alt=media&token=98a955ec-0a93-46b4-99f7-9845cb196ca1",
    },
    {
      code: "CM",
      name: "cameroon",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fcameroon.jpg?alt=media&token=23fe9dd1-4907-43cb-b118-cbadf032dbfc",
    },
    {
      code: "MW",
      name: "malawi",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fmalawi.jpg?alt=media&token=675d3187-bb01-49dd-a94b-df148f0aa78b",
    },
    {
      code: "GM",
      name: "gambia",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fgambia.jpg?alt=media&token=d5a74d6a-c25d-4b43-9bc9-c0f8b8e25919",
    },
    {
      code: "SL",
      name: "sierra-leone",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fsierra_leone.jpg?alt=media&token=d288c034-0341-4a8d-abb3-23186d64e85d",
    },
    {
      code: "CI",
      name: "ivory-coast",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fivory_cost.jpg?alt=media&token=835b52d3-81a9-4279-bad8-99d0eaf92cd3",
    },
    {
      code: "RW",
      name: "rwanda",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Frwanda.jpg?alt=media&token=fd9b29fd-39fc-48b8-b29b-5fb830222602",
    },
    {
      code: "BI",
      name: "burundi",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fburundi.jpg?alt=media&token=40673c94-7c06-48bc-9ff4-1f5643665fdc",
    },
    {
      code: "ZW",
      name: "zimbabwe",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fzimbabwe.jpg?alt=media&token=64c1f394-2aea-46e3-807c-4c64140bdb92",
    },
  ],
  asia: [
    {
      code: "HK",
      name: "hong-kong",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fhongkong.jpg?alt=media&token=a4a99504-0068-40de-a2fb-d1fef80c1dc3",
    },
    {
      code: "IN",
      name: "india",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Findia.jpg?alt=media&token=6a6d089f-47ce-4a70-b3c6-4926bf48f8db",
    },
    {
      code: "CN",
      name: "china",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fchina.jpg?alt=media&token=fe06f30e-2264-46e9-9771-0f3a8d160620",
    },
    {
      code: "TH",
      name: "thailand",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fthailand.jpg?alt=media&token=2f23c608-70b7-4ee1-b10e-9e175fb28fae",
    },
    {
      code: "SG",
      name: "singapore",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fsingapore.jpg?alt=media&token=207d4f18-a240-4633-8a8c-c190d1a2ad99",
    },
    {
      code: "AE",
      name: "uae",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fuae.jpg?alt=media&token=4a13aded-0525-49f5-8500-148e07ee17c5",
    },
    {
      code: "PK",
      name: "pakistan",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fpakistan.jpg?alt=media&token=0875b019-9ea0-4a16-a9da-f26ace80cf67",
    },
    {
      code: "TR",
      name: "turkey",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fturkey.jpg?alt=media&token=b898cfde-8a52-4441-94c8-58aeeb9d455a",
    },
    {
      code: "BD",
      name: "bangladesh",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2Fbangladesh.jpg?alt=media&token=43369603-76cf-4ca4-be87-54ca56405cf1",
    },
    {
      code: "PH",
      name: "philippines",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fphilippines.jpg?alt=media&token=9a50e50d-07a4-40a2-82d0-b4c44a6c1abe",
    },
    {
      code: "MY",
      name: "malaysia",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fmalaysia.jpg?alt=media&token=4e2d6f29-9b6a-4429-93e0-65c6168988bc",
    },
    {
      code: "SA",
      name: "saudi-arab",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2Fsaudi_arabia.jpg?alt=media&token=32f16096-8211-4fe0-89a3-8ffc14b81f28",
    },
  ],
  europe: [
    {
      name: "united-kingdom",
      code: "GB",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
    },
    {
      name: "france",
      code: "FR",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Ffrance.jpg?alt=media&token=18eb3c0e-c58b-4e0d-a71c-67de06acb442",
    },
    {
      code: "BJ",
      name: "benin",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fbenin.jpg?alt=media&token=d4b12210-1e73-4f3d-939e-d70863650951",
    },
    {
      code: "IE",
      name: "ireland",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2Fireland.jpg?alt=media&token=7cdb5737-d74b-4dad-a58c-e721de81f083",
    },
    {
      code: "BE",
      name: "belgium",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2Fbelgium.jpg?alt=media&token=e0ca9511-c809-4313-b3be-0a5d07b16b8d",
    },
  ],
  america: [
    {
      code: "JM",
      name: "jamaica",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fjamaica.jpg?alt=media&token=4e9ce312-164d-4e61-a560-c89744ac3375",
    },
    {
      code: "BB",
      name: "barbados",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fbarbados.jpg?alt=media&token=ccc591a4-89d7-494e-b927-f65be56106cf",
    },
    {
      code: "DM",
      name: "dominica",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2Fdominica.jpg?alt=media&token=e8dc91f3-c7c0-4cc3-b840-2a67d2342746",
    },
    {
      code: "DO",
      name: "dominican-republic",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fdominican_republic.jpg?alt=media&token=d94ab667-b1c8-40fc-b4bb-49308620ed0f",
    },
    {
      code: "GD",
      name: "grenada",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fgrenada.jpg?alt=media&token=eee47ef8-0ac3-46a5-b114-285b3f51ca7f",
    },
  ],
  australia: [
    {
      code: "AU",
      name: "australia",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2FScreenshot%20from%202022-09-02%2011-57-21.png?alt=media&token=a15288f8-8f26-429f-8edc-e92b48c5ac55",
    },
  ],
};

// updated
export const airlines = [
  {
    airlineCode: "TS",
    name: "air-transat",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FAir_Transat.png?alt=media&token=04e72879-f150-4ea0-82be-c7d515751079",
  },
  {
    airlineCode: "EK",
    name: "emirates",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FEmirates.png?alt=media&token=966a5583-c2da-4922-9e21-4c6c41806c53",
  },
  {
    name: "brussels-airlines",
    airlineCode: "SN",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FBrussels_Airline.png?alt=media&token=133abe92-8105-4263-a4c4-9e8f6c993aaf",
  },
  {
    name: "british-airways",
    airlineCode: "BA",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fbritish-airways.png?alt=media&token=94da122b-9d13-46f4-a0fd-2f51fa67e854",
  },
  {
    name: "easy-jet",
    airlineCode: "OS",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FEasyJet_logo.png?alt=media&token=e2b68ce8-ee6e-43b4-9fd9-6f966858fa87",
  },
  {
    airlineCode: "MS",
    name: "egypt-air",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FEgypt_Air.png?alt=media&token=4103d46c-806c-483d-8abd-9fc4c5e5e355",
  },
  {
    airlineCode: "EY",
    name: "etihad-airways",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fetihad-air.png?alt=media&token=92b9a8b2-8c3d-44a1-a62e-f301e64791fe",
  },
  {
    airlineCode: "GF",
    name: "golf-air",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FGulf_Air.png?alt=media&token=43f1a983-ce04-4e41-8af9-2b19f92281fc",
  },
  {
    name: "klm",
    airlineCode: "KL",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FKLM.png?alt=media&token=7f250c71-f96d-406f-a205-bbc5b47ea243",
  },
  {
    airlineCode: "MH",
    name: "malaysia-airlines",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FMalaysia_Airlines.png?alt=media&token=00114098-2a97-403e-8115-8e5184ae9670",
  },
  {
    airlineCode: "WY",
    name: "oman-air",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FOman_Air.png?alt=media&token=2891da79-3b7c-44aa-8275-cf1303e89f60",
  },
  {
    airlineCode: "QF",
    name: "qantas-airways",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FQantas_Airways.png?alt=media&token=4a5fb7bd-a26e-4f55-a3b6-907732a9b372",
  },
  {
    airlineCode: "QR",
    name: "qatar-airways",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FQatar_Airways.png?alt=media&token=d2920ab2-5717-47db-88f5-498b419e09ce",
  },
  {
    airlineCode: "WB",
    name: "rwand-air",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FRwandAir.png?alt=media&token=bd8b4b35-b61f-48ad-8274-1b02d0701620",
  },
  {
    name: "air-france",
    airlineCode: "AF",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fair-france.png?alt=media&token=4d90c961-8b1a-4e82-8ca3-212fc8daf545",
  },
  {
    airlineCode: "SQ",
    name: "singapore-airlines",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FSingapore_Airlines.png?alt=media&token=bd45025b-e103-40b1-aced-866443884cf6",
  },
  {
    airlineCode: "SA",
    name: "south-africa-airways",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FSouth_African_Airways.png?alt=media&token=3a3e435e-62f2-4639-b65a-f409bfde8b74",
  },
  {
    name: "tap-portugal",
    airlineCode: "TP",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FTAP_Portugal.png?alt=media&token=f9fdcb38-65a1-4cfd-b886-eb74d05dd369",
  },
  {
    airlineCode: "TG",
    name: "thai-airways",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FThai_Airways.png?alt=media&token=db24986a-b3c6-45d5-a7ca-c700564425d4",
  },
  {
    airlineCode: "UA",
    name: "united-airlines",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FUnited_Airlines.png?alt=media&token=6dfb9c31-0921-471f-b7de-05a0548f279b",
  },
  {
    airlineCode: "VN",
    name: "vietnam-airlines",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FVietnam_airlines.png?alt=media&token=bf1aa6cb-6686-4509-a34b-f35d39c04952",
  },
  {
    airlineCode: "VS",
    name: "virgin-atlantic",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FVietnam_airlines.png?alt=media&token=bf1aa6cb-6686-4509-a34b-f35d39c04952",
  },
  {
    airlineCode: "VA",
    name: "virgin-australia",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2FVirgin_Australia.png?alt=media&token=dc1ea0dc-a271-453c-8847-1ee81c1733d3",
  },
  {
    name: "american-airlines",
    airlineCode: "AA",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Famerican_airlines.png?alt=media&token=ba8e826e-3f4b-4c53-886c-6eb6c3729155",
  },
  {
    airlineCode: "6E",
    name: "indigo",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Findigo.png?alt=media&token=fbc0fcd5-2505-4a6d-b21c-f7aa3b132c9d",
  },
  {
    airlineCode: "KQ",
    name: "kenya-airways",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fkenyaairways.png?alt=media&token=f95ab3cf-e9fd-4da4-8f8f-1eb03692443f",
  },
  {
    airlineCode: "LH",
    name: "lufthansa",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Flufthansa.png?alt=media&token=9bd76c9e-95ce-4d2d-80f0-f108f8f59da1",
  },
  {
    airlineCode: "PK",
    name: "pia",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fpakistan_international_airlines.png?alt=media&token=3c4e0ac2-826e-409e-8745-24f039f0af62",
  },
  {
    airlineCode: "PC",
    name: "pegasus-airlines",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fpegasus_airlines.png?alt=media&token=3210ecd4-cb87-4179-991b-22622f1e28db",
  },
  {
    airlineCode: "SV",
    name: "saudi-arabian-airlines",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fsaudi_arabian_airlines.png?alt=media&token=ee9ba4ab-822f-49fb-ad1d-815a49fd774b",
  },
  {
    airlineCode: "LX",
    name: "swiss-airlines",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fswiss.png?alt=media&token=62f3e316-5ff5-4634-8f78-45c9bfdf85ef",
  },
  {
    airlineCode: "MT",
    name: "thomas-cook-airlines",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fthomas_cook.png?alt=media&token=f7812caf-470b-4db7-bae1-244f4ee87089",
  },
  {
    name: "turkish-airlines",
    airlineCode: "TK",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fturkish_airlines.png?alt=media&token=7d9c3954-d1ec-4e34-ae8d-c3e0679cf15a",
  },
  {
    airlineCode: "WS",
    name: "west-jet",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/airlines%2Fwestjet.png?alt=media&token=c296126c-65e6-4c78-9286-7d48531b89f2",
  },
];

export const airports = {
  ghana: [
    {
      city: "accra",
      airportCode: "ACC",
      airport: "kotoka-international-airport",
    },
  ],
  uganda: [
    {
      city: "entebbe",
      airportCode: "EBB",
      airport: "entebbe-international-airport",
    },
  ],
  usa: [
    {
      city: "queens",
      airportCode: "JFK",
      airport: "john-f-kennedy-international-airport",
    },
    {
      city: "mexico",
      airportCode: "MEX",
      airport: "mexico-city-international-airport",
    },
    {
      city: "florida",
      airportCode: "MCO",
      airport: "orlando-international-airport",
    },
    {
      city: "virginia",
      airportCode: "IAD",
      airport: "dulles-international-airport",
    },
    {
      city: "texas",
      airportCode: "IAH",
      airport: "george-bush-intercontinental-airport",
    },
    {
      city: "boston",
      airportCode: "BOS",
      airport: "boston-logan-international-airport",
    },
    {
      city: "maryland",
      airportCode: "BWI",
      airport: "baltimore/washington-international-thurgood-mrshall-airport",
    },
    {
      city: "california",
      airportCode: "SFO",
      airport: "sanfrancisco-international-airport",
    },
  ],
  cameroon: [
    {
      city: "douala",
      airportCode: "DLA",
      airport: "douala-international-airport",
    },
  ],
  congo: [
    {
      city: "kinshasa",
      airportCode: "FIH",
      airport: "ndjili-international-airport-kinshasa",
    },
  ],
  "sierra-leone": [
    {
      city: "lungi",
      airportCode: "FNA",
      airport: "lungi-international-airport",
    },
  ],
  uae: [
    {
      city: "dubai",
      airportCode: "DXB",
      airport: "dubai-international-airport",
    },
    {
      city: "abu-dhabi",
      airportCode: "AUH",
      airport: "abu-dhabi-international-airport",
    },
  ],
  jamaica: [
    {
      city: "kingston",
      airportCode: "KIN",
      airport: "norman-manley-international-airport",
    },
    {
      city: "montego-bay",
      airportCode: "MBJ",
      airport: "sangster-international-airport-montego-bay",
    },
  ],
  malawi: [
    {
      city: "lilongwe",
      airportCode: "LLW",
      airport: "kamuzu-international-airport",
    },
  ],
  zambia: [
    {
      city: "lusaka",
      airportCode: "LUN",
      airport: "kenneth-kaunda-international-airport",
    },
  ],
  canada: [
    {
      city: "toronto",
      airportCode: "YYZ",
      airport: "toronto-pearson-international-airport",
    },
    {
      city: "calgary",
      airportCode: "YYC",
      airport: "calgary-international-airport",
    },
  ],
  "ivory-coast": [
    {
      city: "port-bouët",
      airportCode: "ABJ",
      airport: "félix-houphouët-boigny-international-airport",
    },
  ],
  australia: [
    {
      city: "melbourne",
      airportCode: "MEL",
      airport: "melbourne-airport",
    },
    {
      city: "perth",
      airportCode: "PER",
      airport: "perth-international-airport",
    },
  ],
  kenya: [
    {
      city: "nairobi",
      airportCode: "NBO",
      airport: "jomo-kenyatta-international-airport",
    },
    {
      city: "mombasa",
      airportCode: "MBA",
      airport: "moi-international-airport",
    },
  ],
  ethiopia: [
    {
      city: "addis-ababa",
      airportCode: "ADD",
      airport: "addis-ababa-bole-international-airport",
    },
  ],
  morocco: [
    {
      city: "casablanca",
      airportCode: "CMN",
      airport: "mohammed-v-international-airport",
    },
  ],
  nigeria: [
    {
      city: "abuja",
      airportCode: "ABV",
      airport: "nnamdi-azikiwe-international-airport",
    },
    {
      city: "lagos",
      airportCode: "LOS",
      airport: "murtala-muhammed-international-airport",
    },
    {
      city: "port-harcourt",
      airportCode: "PHC",
      airport: "port-harcourt-international-airport",
    },
  ],
  barbados: [
    {
      city: "seawell",
      airportCode: "BGI",
      airport: "grantley-adams-international-airport",
    },
  ],
  egypt: [
    {
      city: "cairo",
      airportCode: "CAI",
      airport: "cairo-international-airport",
    },
  ],
  "south-africa": [
    {
      city: "cape-town",
      airportCode: "CPT",
      airport: "cape-town-international-airport",
    },
    {
      city: "gauteng",
      airportCode: "JNB",
      airport: "o-r-tambo-international-airport",
    },
  ],
  tanzania: [
    {
      city: "dar-es-salaam",
      airportCode: "DAR",
      airport: "julius-nyerere-international-airport",
    },
  ],
  singapore: [
    {
      city: "changi",
      airportCode: "SIN",
      airport: "singapore-changi-airport",
    },
  ],
  india: [
    {
      city: "mumbai",
      airportCode: "BOM",
      airport: "chhatrapati-shivaji-maharaj-international-airport",
    },
  ],
  "hong-kong": [
    {
      city: "hong-kong",
      airportCode: "HKG",
      airport: "hong-kong-international-airport",
    },
  ],
  turkey: [
    {
      city: "istanbul",
      airportCode: "IST",
      airport: "istanbul-international-airport",
    },
  ],
  Bangladesh: [
    {
      city: "dhaka",
      airportCode: "DAC",
      airport: "bangladesh-international-airport",
    },
  ],
  malaysia: [
    {
      city: "kuala-lumpur",
      airportCode: "KUL",
      airport: "kuala-lumpur-international-airport",
    },
  ],
  "new-zealand": [
    {
      city: "aukland",
      airportCode: "AKL",
      airport: "auckland-airport",
    },
  ],
  france: [
    {
      city: "paris",
      airportCode: "CDG",
      airport: "paris-charles-de-gaulle-airport",
    },
  ],
  "united-kingdom": [
    {
      city: "london",
      airportCode: "LHR",
      airport: "london-heathrow-airport",
    },
    {
      city: "london",
      airportCode: "LCY",
      airport: "london-city-airport",
    },
    {
      city: "london",
      airportCode: "LGW",
      airport: "london-gatwick-airport",
    },
    {
      city: "manchester",
      airportCode: "MAN",
      airport: "manchester-airport",
    },
    {
      city: "birmingham",
      airportCode: "BHX",
      airport: "birmingham-airport",
    },
    {
      city: "scotland",
      airportCode: "GLA",
      airport: "glasgow-airport",
    },
  ],
};

export const fares = [
  {
    destAirport: {
      airport: "félix-houphouët-boigny-international-airport",
      city: "port-bouët",
      airportCode: "ABJ",
    },
    deptContinent: "europe",
    airline: {
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2FQatar_Airways.png?alt=media&token=ec702cdc-a600-4302-a73d-0cd80b2ca7cb",
      airlineCode: "QR",
      name: "qatar-airways",
    },
    type: "economy",
    price: "413",
    destCountry: {
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fivory_cost.jpg?alt=media&token=835b52d3-81a9-4279-bad8-99d0eaf92cd3",
      code: "CI",
      name: "ivory-coast",
    },
    deptAirport: {
      airportCode: "LGW",
      airport: "london-gatwick-airport",
      city: "london",
    },
    destContinent: "africa",
    deptCountry: {
      code: "GB",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
      name: "united-kingdom",
    },
    _id: "0FLZNI2KcDK65sB6doTX",
    top: "true",
  },
  {
    destAirport: {
      airport: "o-r-tambo-international-airport",
      city: "gauteng",
      airportCode: "JNB",
    },
    type: "economy",
    destCountry: {
      name: "south-africa",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fsouth_africa.jpg?alt=media&token=42c5c811-3841-4571-ac02-3fb5e5c8296d",
      code: "ZA",
    },
    destContinent: "africa",
    deptAirport: {
      airport: "manchester-airport",
      airportCode: "MAN",
      city: "manchester",
    },
    price: "525",
    deptContinent: "europe",
    deptCountry: {
      code: "GB",
      name: "united-kingdom",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
    },
    airline: {
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2FVirgin_Atlantic.png?alt=media&token=1c21a692-a328-4e55-8ae6-a3d0721adbf3",
      name: "virgin-atlantic",
      airlineCode: "VS",
    },
    _id: "0OheKIbZp63NkQAyIpDq",
    top: "true",
  },
  {
    destCountry: {
      code: "NG",
      name: "nigeria",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fnigeria.jpg?alt=media&token=5401f7ce-9b81-4214-841c-d30b4eaa3fef",
    },
    deptCountry: {
      name: "united-kingdom",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
      code: "GB",
    },
    price: "451",
    deptAirport: {
      airportCode: "MAN",
      airport: "manchester-airport",
      city: "manchester",
    },
    airline: {
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2FKLM.png?alt=media&token=0f58e83c-dd48-4f3d-aa56-30c41e1ea578",
      name: "klm",
      airlineCode: "KL",
    },
    destContinent: "africa",
    destAirport: {
      city: "lagos",
      airport: "murtala-muhammed-international-airport",
      airportCode: "LOS",
    },
    type: "economy",
    deptContinent: "europe",
    _id: "0RkvbWjtsO1ez2EdstlT",
    top: "true",
  },

  {
    deptAirport: {
      airportCode: "LHR",
      airport: "london-heathrow-airport",
      city: "london",
    },
    deptCountry: {
      code: "GB",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
      name: "united-kingdom",
    },
    airline: {
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2Flufthansa.png?alt=media&token=7a364ece-0e49-42b8-afef-937ea1fdf5be",
      name: "lufthansa",
      airlineCode: "LH",
    },
    type: "economy",
    destCountry: {
      code: "NG",
      name: "nigeria",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fnigeria.jpg?alt=media&token=5401f7ce-9b81-4214-841c-d30b4eaa3fef",
    },
    destContinent: "africa",
    price: "473",
    deptContinent: "europe",
    destAirport: {
      airportCode: "ABV",
      city: "abuja",
      airport: "nnamdi-azikiwe-international-airport",
    },
    _id: "0lmawc0Tqem90IBtVtYI",
    top: "true",
  },

  {
    type: "economy",
    price: "409",
    deptCountry: {
      code: "GB",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
      name: "united-kingdom",
    },
    deptContinent: "europe",
    deptAirport: {
      airportCode: "LHR",
      airport: "london-heathrow-airport",
      city: "london",
    },
    airline: {
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2FTAP_Portugal.png?alt=media&token=35fa534a-601b-4340-ba72-b6b02aedf25b",
      name: "tap-portugal",
      airlineCode: "TP",
    },
    destCountry: {
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fnewyork.png?alt=media&token=7389073e-394c-4f5e-98b3-bbc7948b1314",
      code: "US",
      name: "usa",
    },
    destContinent: "usa",
    destAirport: {
      city: "florida",
      airportCode: "MCO",
      airport: "orlando-international-airport",
    },
    _id: "18ZhkMNUPQVMbjEo079D",
    top: "true",
  },
  {
    deptCountry: {
      name: "united-kingdom",
      code: "GB",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
    },
    deptAirport: {
      airportCode: "BHX",
      airport: "birmingham-airport",
      city: "birmingham",
    },
    price: "459",
    destCountry: {
      code: "KE",
      name: "kenya",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fkenya.jpg?alt=media&token=bda17472-b25b-4b28-955a-13ef4ab05b92",
    },
    deptContinent: "europe",
    airline: {
      airlineCode: "AF",
      name: "air-france",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2Fair-france.png?alt=media&token=58b697dc-3b90-48a2-bb70-ddf8653fce79",
    },
    type: "economy",
    destContinent: "africa",
    destAirport: {
      city: "nairobi",
      airportCode: "NBO",
      airport: "jomo-kenyatta-international-airport",
    },
    _id: "1DRggZsB4K2D0SeuumbZ",
    top: "true",
  },
  {
    deptContinent: "europe",
    price: "421",
    deptAirport: {
      city: "london",
      airportCode: "LCY",
      airport: "london-city-airport",
    },
    destAirport: {
      airport: "kamuzu-international-airport",
      airportCode: "LLW",
      city: "lilongwe",
    },
    destContinent: "africa",
    type: "economy",
    destCountry: {
      name: "malawi",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fmalawi.jpg?alt=media&token=675d3187-bb01-49dd-a94b-df148f0aa78b",
      code: "MW",
    },
    deptCountry: {
      name: "united-kingdom",
      code: "GB",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
    },
    airline: {
      name: "kenya-airways",
      airlineCode: "KQ",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2Fkenyaairways.png?alt=media&token=443a3246-1f00-411f-ad68-f31f691f7e74",
    },
    _id: "1HITD4RUOiCXW7bu1bNW",
    top: "true",
  },

  {
    deptAirport: {
      airportCode: "LCY",
      airport: "london-city-airport",
      city: "london",
    },
    price: "409",
    destCountry: {
      name: "tanzania",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Ftanzania.jpg?alt=media&token=98a47363-a786-4d79-96f8-8100d5af6932",
      code: "TZ",
    },
    type: "economy",
    airline: {
      name: "turkish-airlines",
      airlineCode: "TK",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2Fturkish_airlines.png?alt=media&token=da3f6267-3b3f-4615-82ba-b0d81ba2116d",
    },
    deptCountry: {
      name: "united-kingdom",
      code: "GB",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
    },
    destAirport: {
      airport: "julius-nyerere-international-airport",
      city: "dar-es-salaam",
      airportCode: "DAR",
    },
    deptContinent: "europe",
    destContinent: "africa",
    _id: "1zGcMieMmBkHDJxxfDjp",
    top: "true",
  },
  {
    deptAirport: {
      airport: "glasgow-airport",
      airportCode: "GLA",
      city: "scotland",
    },
    destAirport: {
      airportCode: "PER",
      airport: "perth-international-airport",
      city: "perth",
    },
    destContinent: "australia",
    type: "economy",
    airline: {
      airlineCode: "BA",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2Fbritish-airways.png?alt=media&token=1faed315-0503-4d92-a810-f60ca61d6cf4",
      name: "british-airways",
    },
    deptContinent: "europe",
    deptCountry: {
      code: "GB",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
      name: "united-kingdom",
    },
    destCountry: {
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2FScreenshot%20from%202022-09-02%2011-57-21.png?alt=media&token=a15288f8-8f26-429f-8edc-e92b48c5ac55",
      code: "AU",
      name: "australia",
    },
    price: "753",
    _id: "20hrwYqv7UagVH0QAjGU",
    top: "true",
  },
  {
    type: "economy",
    destAirport: {
      airportCode: "MEX",
      city: "mexico",
      airport: "mexico-city-international-airport",
    },
    airline: {
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2FKLM.png?alt=media&token=0f58e83c-dd48-4f3d-aa56-30c41e1ea578",
      airlineCode: "KL",
      name: "klm",
    },
    destCountry: {
      name: "usa",
      img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fnewyork.png?alt=media&token=7389073e-394c-4f5e-98b3-bbc7948b1314",
      code: "US",
    },
    deptAirport: {
      city: "london",
      airportCode: "LHR",
      airport: "london-heathrow-airport",
    },
    price: "687",
    deptContinent: "europe",
    deptCountry: {
      name: "united-kingdom",
      code: "GB",
      img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
    },
    destContinent: "usa",
    _id: "20iyy2xMA5TEILZdurzG",
    top: "true",
  },
  // {
  //   destContinent: "australia",
  //   price: "641",
  //   airline: {
  //     name: "etihad-airways",
  //     airlineCode: "EY",
  //     img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2Fetihad-air.png?alt=media&token=158432fb-f303-4492-8990-9319205eef67",
  //   },
  //   deptCountry: {
  //     code: "GB",
  //     name: "united-kingdom",
  //     img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
  //   },
  //   deptContinent: "europe",
  //   type: "economy",
  //   destAirport: {
  //     airportCode: "PER",
  //     city: "perth",
  //     airport: "perth-international-airport",
  //   },
  //   deptAirport: {
  //     airportCode: "LHR",
  //     city: "london",
  //     airport: "london-heathrow-airport",
  //   },
  //   destCountry: {
  //     name: "australia",
  //     img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2FScreenshot%20from%202022-09-02%2011-57-21.png?alt=media&token=a15288f8-8f26-429f-8edc-e92b48c5ac55",
  //     code: "AU",
  //   },
  //   _id: "294qfPg08UNLrjPez0t9",
  // },
  // {
  //   price: "502",
  //   type: "economy",
  //   airline: {
  //     img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2FEgypt_Air.png?alt=media&token=05541fb3-a54d-4b6d-a557-ed7abab231e4",
  //     name: "egypt-air",
  //     airlineCode: "MS",
  //   },
  //   deptAirport: {
  //     city: "london",
  //     airportCode: "LHR",
  //     airport: "london-heathrow-airport",
  //   },
  //   destContinent: "africa",
  //   destCountry: {
  //     code: "MW",
  //     img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fmalawi.jpg?alt=media&token=675d3187-bb01-49dd-a94b-df148f0aa78b",
  //     name: "malawi",
  //   },
  //   deptCountry: {
  //     code: "GB",
  //     name: "united-kingdom",
  //     img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
  //   },
  //   deptContinent: "europe",
  //   destAirport: {
  //     airport: "kamuzu-international-airport",
  //     airportCode: "LLW",
  //     city: "lilongwe",
  //   },
  //   _id: "2GCf2bYYGFJlBIZ619JX",
  // },
  // {
  //   type: "economy",
  //   deptAirport: {
  //     city: "birmingham",
  //     airport: "birmingham-airport",
  //     airportCode: "BHX",
  //   },
  //   destCountry: {
  //     img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/destinations%2Fsierra_leone.jpg?alt=media&token=d288c034-0341-4a8d-abb3-23186d64e85d",
  //     name: "sierra-leone",
  //     code: "SL",
  //   },
  //   destContinent: "africa",
  //   deptCountry: {
  //     code: "GB",
  //     name: "united-kingdom",
  //     img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/destinations%2FScreenshot%20from%202022-09-01%2019-48-23.png?alt=media&token=94134b39-2092-47f3-a46a-33aa6bc345e5",
  //   },
  //   airline: {
  //     img: "https://firebasestorage.googleapis.com/v0/b/fasttravels92.appspot.com/o/airlines%2Fair-france.png?alt=media&token=58b697dc-3b90-48a2-bb70-ddf8653fce79",
  //     airlineCode: "AF",
  //     name: "air-france",
  //   },
  //   price: "390",
  //   destAirport: {
  //     city: "lungi",
  //     airportCode: "FNA",
  //     airport: "lungi-international-airport",
  //   },
  //   deptContinent: "europe",
  //   _id: "2mInJln3kNAMHeKaqRm2",
  // },
];

export const searchEngine = [
  {
    city: "a-coruna",
    airport: "a-coruna-airport",
    airportCode: "LCG",
    country: "spain",
  },
  {
    city: "aberdeen",
    airport: "dyce-airport",
    airportCode: "ABZ",
    country: "united-kingdom",
  },
  {
    city: "abu-dhabi",
    airport: "abu-dhabi-airport",
    airportCode: "AUH",
    country: "uae",
  },
  {
    city: "accra",
    airport: "kotoka-airport",
    airportCode: "ACC",
    country: "ghana",
  },
  {
    city: "adelaide",
    airport: "adelaide-airport",
    airportCode: "ADL",
    country: "australia",
  },
  {
    city: "agadir",
    airport: "al-massiraa-airport",
    airportCode: "AGA",
    country: "morocco",
  },
  {
    city: "ahmedabad",
    airport: "sardar-vallabhbhai-patel-airport",
    airportCode: "AMD",
    country: "india",
  },
  {
    city: "al-ain",
    airport: "etihad-bus-station",
    airportCode: "ZVH",
    country: "uae",
  },
  {
    city: "alesund",
    airport: "vigra-airport",
    airportCode: "AES",
    country: "norway",
  },
  {
    city: "alexandria",
    airport: "borg-el-arab-airport",
    airportCode: "HBE",
    country: "egypt",
  },
  {
    city: "algiers",
    airport: "houari-boumediene-airport",
    airportCode: "ALG",
    country: "algeria",
  },
  {
    city: "alicante",
    airport: "alicante-airport",
    airportCode: "ALC",
    country: "spain",
  },
  {
    city: "alice-springs",
    airport: "alice-springs-airport",
    airportCode: "ASP",
    country: "australia",
  },
  {
    city: "alor-setar",
    airport: "sultan-abdul-halim-airport",
    airportCode: "AOR",
    country: "malaysia",
  },
  {
    city: "amman",
    airport: "queen-alia-airport",
    airportCode: "AMM",
    country: "jordan",
  },
  {
    city: "amritsar",
    airport: "sri-guru-ram-dass-jee-airport ",
    airportCode: "ATQ",
    country: "india",
  },
  {
    city: "amsterdam",
    airport: "schiphol-airport",
    airportCode: "AMS",
    country: "netherlands",
  },
  {
    city: "ancona",
    airport: "falconara-airport",
    airportCode: "AOI",
    country: "italy",
  },
  {
    city: "angers",
    airport: "saint-laud-TGV-railway-station",
    airportCode: "OXG",
    country: "france",
  },
  {
    city: "assuit",
    airport: "assuit-airport",
    airportCode: "ATZ",
    country: "egypt",
  },
  {
    city: "asuncion",
    airport: "silvio-pettirossi-airport",
    airportCode: "ASU",
    country: "paraguay",
  },
  {
    city: "athens",
    airport: "eleftherios-venizelos-airport",
    airportCode: "ATH",
    country: "greece",
  },
  {
    city: "atlanta-GA",
    airport: "hartsfield-jackson-airport",
    airportCode: "ATL",
    country: "usa",
  },
  {
    city: "austin-TX",
    airport: "bergstrom-airport",
    airportCode: "AUS",
    country: "usa",
  },
  {
    city: "auckland",
    airport: "auckland-airport",
    airportCode: "AKL",
    country: "new-Zealand",
  },
  {
    city: "avignon",
    airport: "avignon-TGV-railway-station",
    airportCode: "XZN",
    country: "france",
  },
  {
    city: "bagdogra",
    airport: "bagdogra-airport",
    airportCode: "IXB",
    country: "india",
  },
  {
    city: "baghdad",
    airport: "baghdad-airport",
    airportCode: "BGW",
    country: "iraq",
  },
  {
    city: "bahrain",
    airport: "bahrain-airport",
    airportCode: "BAH",
    country: "bahrain",
  },
  {
    city: "baku",
    airport: "heydar-aliyev-airport",
    airportCode: "GYD",
    country: "azerbaijan",
  },
  {
    city: "bali",
    airport: "ngurah-rai-airport",
    airportCode: "DPS",
    country: "indonesia",
  },
  {
    city: "balikpapan",
    airport: "sepinggan-airport",
    airportCode: "BPN",
    country: "indonesia",
  },
  {
    city: "ballina",
    airport: "byron-gateway-airport",
    airportCode: "BNK",
    country: "australia",
  },
  {
    city: "baltimore-MD",
    airport: "washington-thurgood-marshall-airport",
    airportCode: "BWI",
    country: "usa",
  },
  {
    city: "barcelona",
    airport: "el-prat-airport ",
    airportCode: "BCN",
    country: "spain",
  },
  {
    city: "bari",
    airport: "bari-central-railway-station",
    airportCode: "BAU",
    country: "italy",
  },
  {
    city: "bari",
    airport: "palese-airport",
    airportCode: "BRI",
    country: "italy",
  },
  {
    city: "basel",
    airport: "basel-badischer-railway-station",
    airportCode: "ZBA",
    country: "switzerland",
  },
  {
    city: "basel",
    airport: "euro-airport",
    airportCode: "BSL",
    country: "switzerland",
  },
  {
    city: "bath",
    airport: "bath-spa-railway-station",
    airportCode: "QQX",
    country: "united-kingdom",
  },
  {
    city: "beijing",
    airport: "beijing-all-airports",
    airportCode: "BJS",
    country: "china",
  },
  {
    city: "beirut",
    airport: "rafic-hariri-airport",
    airportCode: "BEY",
    country: "lebanon",
  },
  {
    city: "belfast",
    airport: "george-best-city-airport ",
    airportCode: "BHD",
    country: "united-kingdom",
  },
  {
    city: "belgrade",
    airport: "nikola-tesla-airport",
    airportCode: "BEG",
    country: "serbia",
  },
  {
    city: "bengaluru",
    airport: "kempegowda-airport",
    airportCode: "BLR",
    country: "india",
  },
  {
    city: "bergen",
    airport: "fleslan-airport",
    airportCode: "BGO",
    country: "norway",
  },
  {
    city: "berlin",
    airport: "berlin-central-railway-station",
    airportCode: "QPP",
    country: "germany",
  },
  {
    city: "berlin",
    airport: "brandnburg-airport",
    airportCode: "BER",
    country: "germany",
  },
  {
    city: "bhopal",
    airport: "bhopal-airport",
    airportCode: "BHO",
    country: "india",
  },
  {
    city: "bhubaneswar",
    airport: "biju-patnaik-airport",
    airportCode: "BBI",
    country: "india",
  },
  {
    city: "bilbao",
    airport: "bilbao-airport",
    airportCode: "BIO",
    country: "spain",
  },
  {
    city: "billund",
    airport: "billund-airport",
    airportCode: "BLL",
    country: "denmark",
  },
  {
    city: "bintulu",
    airport: "bintulu-airport",
    airportCode: "BTU",
    country: "malaysia",
  },
  {
    city: "birmingham-AL",
    airport: "shuttlesworth-airport ",
    airportCode: "BHM",
    country: "usa",
  },
  {
    city: "bloomington-normal-IL",
    airport: "normal-airport ",
    airportCode: "BMI",
    country: "usa",
  },
  {
    city: "bologna",
    airport: "bologna-central-railway-station",
    airportCode: "IBT",
    country: "italy",
  },
  {
    city: "bogota",
    airport: "el-dorado-airport",
    airportCode: "BOG",
    country: "colombia",
  },
  {
    city: "bordeaux",
    airport: "merignac-airport",
    airportCode: "BOD",
    country: "france",
  },
  {
    city: "bordeaux",
    airport: "saint-jean-TGV-railway-station",
    airportCode: "ZFQ",
    country: "france",
  },
  {
    city: "bologna",
    airport: "guglielmo-marconi-airport",
    airportCode: "BLQ",
    country: "italy",
  },
  {
    city: "boston-MA",
    airport: "lLogan-airport",
    airportCode: "BOS",
    country: "usa",
  },
  {
    city: "bridgwater",
    airport: "bridgwater-railway-station",
    airportCode: "UBW",
    country: "united-kingdom",
  },
  {
    city: "brindisi",
    airport: "brindisi-railway-station",
    airportCode: "BQD",
    city: "brindisi",
  },
  {
    city: "brindisi",
    airport: "casale-airport",
    airportCode: "BDS",
    city: "brindisi",
  },
  {
    city: "brisbane",
    airport: "brisbane-airport",
    airportCode: "BNE",
    country: "australia",
  },
  {
    city: "bristol",
    airport: "lulsgate-airport",
    airportCode: "BRS",
    country: "united-kingdom",
  },
  {
    city: "brussels",
    airport: "brussels-airport",
    airportCode: "BRU",
    country: "belgium",
  },
  {
    city: "brussels",
    airport: "midi-railway-station",
    airportCode: "ZYR",
    country: "belgium",
  },
  {
    city: "bucharest",
    airport: "henri-coanda-otopeni-airport",
    airportCode: "OTP",
    country: "romania",
  },
  {
    city: "budapest",
    airport: "liszt-ferenc-airport",
    airportCode: "BUD",
    country: "hungary",
  },
  {
    city: "buenos-aires",
    airport: "ministro-pistarini-airport",
    airportCode: "EZE",
    country: "argentina",
  },
  {
    city: "buffalo-NY",
    airport: "niagara-airport",
    airportCode: "BUF",
    country: "usa",
  },
  {
    city: "burbank-CA",
    airport: "bob-hope-airport",
    airportCode: "BUR",
    country: "usa",
  },
  {
    city: "burlington-VT",
    airport: "burlington-airport",
    airportCode: "BTV",
    country: "usa",
  },
  {
    city: "cabries",
    airport: "aix-en-provence-TGV-railway-station",
    airportCode: "QXB",
    country: "france",
  },
  {
    city: "cagliari",
    airport: "elmas-airport",
    airportCode: "CAG",
    country: "italy",
  },
  {
    city: "cairns",
    airport: "cairns-airport",
    airportCode: "CNS",
    country: "australia",
  },
  {
    city: "cairo",
    airport: "cairo-airport",
    airportCode: "CAI",
    country: "egypt",
  },
  {
    city: "calgary",
    airport: "calgary-airport",
    airportCode: "YYC",
    country: "canada",
  },
  {
    city: "canberra",
    airport: "canberra-airport",
    airportCode: "CBR",
    country: "australia",
  },
  {
    city: "cancun",
    airport: "cancun-airport",
    airportCode: "CUN",
    country: "mexico",
  },
  {
    city: "cardiff",
    airport: "cardiff-wales-airport",
    airportCode: "CWL",
    country: "united-kingdom",
  },
  {
    city: "casablanca",
    airport: "mohammed-v-airport",
    airportCode: "CMN",
    country: "morocco",
  },
  {
    city: "caserta",
    airport: "caserta-railway-station",
    airportCode: "CTJ",
    country: "italy",
  },
  {
    city: "catania",
    airport: "fontanarossa-airport",
    airportCode: "CTA",
    country: "italy",
  },
  {
    city: "cedar-rapids-ia",
    airport: "the-eastern-iowa-airport",
    airportCode: "CID",
    country: "usa",
  },
  {
    city: "champaign-il",
    airport: "willard-university-airport",
    airportCode: "CMI",
    country: "usa",
  },
  {
    city: "chandigarh",
    airport: "chandigarh-airport",
    airportCode: "IXC",
    country: "india",
  },
  {
    city: "chania",
    airport: "ioannis-daskalogiannis-airport",
    airportCode: "chq",
    country: "greece",
  },
  {
    city: "charleston-sc",
    airport: "air-force-base-airport",
    airportCode: "chs",
    country: "usa",
  },
  {
    city: "chiang-mai",
    airport: "chiang-mai-airport",
    airportCode: "CNX",
    country: "thailand",
  },
  {
    city: "chicago-il",
    airport: "ohare-airport",
    airportCode: "ORD",
    country: "usa",
  },
  {
    city: "chios",
    airport: "chios-airport",
    airportCode: "JKH",
    country: "greece",
  },
  {
    city: "chittagong",
    airport: "shah-amanat-airport",
    airportCode: "CGP",
    country: "bangladesh",
  },
  {
    city: "christchurch",
    airport: "christchurch-airport",
    airportCode: "CHC",
    country: "new-zealand",
  },
  {
    city: "cincinnati-oh",
    airport: "covington-northern-kentucky-airport",
    airportCode: "CVG",
    country: "usa",
  },
  {
    city: "cleveland-oh",
    airport: "hopkins-airport",
    airportCode: "CLE",
    country: "usa",
  },
  {
    city: "coffs-harbour",
    airport: "coffs-harbour-airport",
    airportCode: "CFS",
    country: "australia",
  },
  {
    city: "cologne",
    airport: "cologne-bonn-airport",
    airportCode: "CGN",
    country: "germany",
  },
  {
    city: "cologne",
    airport: "cologne-railway-station",
    airportCode: "QKL",
    country: "germany",
  },
  {
    city: "colombo",
    airport: "bandaranaike-airport",
    airportCode: "CMB",
    country: "sri-lanka",
  },
  {
    city: "columbia-mo",
    airport: "columbia-regional-airport",
    airportCode: "COU",
    country: "usa",
  },
  {
    city: "columbus-oh",
    airport: "port-columbus-airport",
    airportCode: "CMH",
    country: "usa",
  },
  {
    city: "corfu",
    airport: "ioannis-kapodistrias-airport",
    airportCode: "CFU",
    country: "greece",
  },
  {
    city: "cork",
    airport: "cork-airport",
    airportCode: "ORK",
    country: "ireland",
  },
  {
    city: "da-nang",
    airport: "da-nang-airport",
    airportCode: "DAD",
    country: "vietnam",
  },
  {
    city: "dakar",
    airport: "blaise-diagne-airport",
    airportCode: "DSS",
    country: "senegal",
  },
  {
    city: "dalian",
    airport: "zhoushuizi-airport",
    airportCode: "DLC",
    country: "china",
  },
  {
    city: "dallas-tx",
    airport: "fort-worth-airport",
    airportCode: "DFW",
    country: "usa",
  },
  {
    city: "dammam",
    airport: "king-fahd-airport",
    airportCode: "DMM",
    country: "saudi-arabia",
  },
  {
    city: "dar-es-salaam",
    airport: "julius-nyerere-airport",
    airportCode: "DAR",
    country: "tanzania",
  },
  {
    city: "darwin",
    airport: "darwin-airport",
    airportCode: "DRW",
    country: "australia",
  },
  {
    city: "dayton-oh",
    airport: "james-m-cox-airport",
    airportCode: "DAY",
    country: "usa",
  },
  {
    city: "denver-co",
    airport: "denver-airport",
    airportCode: "DEN",
    country: "usa",
  },
  {
    city: "des-moines-ia",
    airport: "des-moines-airport",
    airportCode: "DSM",
    country: "usa",
  },
  {
    city: "detroit-mi",
    airport: "wayne-county-metro-airport",
    airportCode: "DTW",
    country: "usa",
  },
  {
    city: "dhaka",
    airport: "hazrat-shahjalal-airport",
    airportCode: "DAC",
    country: "bangladesh",
  },
  {
    city: "doha",
    airport: "doha-airport",
    airportCode: "DIA",
    country: "qatar",
  },
  {
    city: "doha",
    airport: "hamad-airport",
    airportCode: "DOH",
    country: "qatar",
  },
  {
    city: "donegal",
    airport: "donegal-airport",
    airportCode: "CFN",
    country: "ireland",
  },
  {
    city: "dortmund",
    airport: "dortmund-airport",
    airportCode: "DTM",
    country: "germany",
  },
  {
    city: "dresden",
    airport: "dresden-airport",
    airportCode: "DRS",
    country: "germany",
  },
  {
    city: "dubai",
    airport: "etihad-bus-station",
    airportCode: "XNB",
    country: "uae",
  },
  {
    city: "dublin",
    airport: "dublin-airport",
    airportCode: "DUB",
    country: "ireland",
  },
  {
    city: "dubrovnik",
    airport: "dubrovnik-airport",
    airportCode: "DBV",
    country: "croatia",
  },
  {
    city: "dubuque-ia",
    airport: "dubuque-regional-airport",
    airportCode: "DBQ",
    country: "usa",
  },
  {
    city: "dunedin",
    airport: "dunedin-airport",
    airportCode: "DUD",
    country: "new-zealand",
  },
  {
    city: "durham",
    airport: "teesside-airport",
    airportCode: "MME",
    country: "united-kingdom",
  },
  {
    city: "dusseldorf",
    airport: "dusseldorf-airport",
    airportCode: "DUS",
    country: "germany",
  },
  {
    city: "dusseldorf",
    airport: "dusseldorf-railway-station",
    airportCode: "QDU",
    country: "germany",
  },
  {
    city: "edinburgh",
    airport: "edinburgh-airport",
    airportCode: "EDI",
    country: "united-kingdom",
  },
  {
    city: "edmonton",
    airport: "edmonton-airport",
    airportCode: "YEG",
    country: "canada",
  },
  {
    city: "el-paso-tx",
    airport: "el-paso-airport",
    airportCode: "ELP",
    country: "usa",
  },
  {
    city: "emerald",
    airport: "emerald-airport",
    airportCode: "EMD",
    country: "australia",
  },
  {
    city: "entebbe",
    airport: "entebbe-airport",
    airportCode: "EBB",
    country: "uganda",
  },
  {
    city: "ercan",
    airport: "ercan-airport",
    airportCode: "ECN",
    country: "cyprus",
  },
  {
    city: "eugene-or",
    airport: "mahlon-sweet-field-airport",
    airportCode: "EUG",
    country: "usa",
  },
  {
    city: "evansville-in",
    airport: "evansville-airport",
    airportCode: "EVV",
    country: "usa",
  },
  {
    city: "exeter",
    airport: "exeter-airport",
    airportCode: "EXT",
    country: "united-kingdom",
  },
  {
    city: "faisalabad",
    airport: "faisalabad-airport",
    airportCode: "LYP",
    country: "pakistan",
  },
  {
    city: "fargo-nd",
    airport: "hector-airport",
    airportCode: "FAR",
    country: "usa",
  },
  {
    city: "faro",
    airport: "faro-airport",
    airportCode: "FAO",
    country: "portugal",
  },
  {
    city: "fayetteville-ar",
    airport: "northwest-arkansas-airport",
    airportCode: "XNA",
    country: "usa",
  },
  {
    city: "flint-MI",
    airport: "bishop-airport",
    airportCode: "FNT",
    country: "usa",
  },
  {
    city: "florence",
    airport: "firenze-santa-maria-novella-railway-station",
    airportCode: "ZMS",
    country: "italy",
  },
  {
    city: "florence",
    airport: "peretola-airport",
    airportCode: "FLR",
    country: "italy",
  },
  {
    city: "fort-lauderdale-FL",
    airport: "hollywood-airport",
    airportCode: "FLL",
    country: "usa",
  },
  {
    city: "fort-myers-FL",
    airport: "southwest-florida-airport",
    airportCode: "RSW",
    country: "usa",
  },
  {
    city: "fort-wayne-IN",
    airport: "fort-wayne-airport",
    airportCode: "FWA",
    country: "usa",
  },
  {
    city: "frankfurt",
    airport: "frankfurt-airport",
    airportCode: "FRA",
    country: "germany",
  },
  {
    city: "fresno-CA",
    airport: "yosemite-airport",
    airportCode: "FAT",
    country: "usa",
  },
  {
    city: "fuerteventura",
    airport: "fuerteventura-airport",
    airportCode: "FUE",
    country: "spain",
  },
  {
    city: "fukuoka",
    airport: "fukuoka-airport",
    airportCode: "FUK",
    country: "japan",
  },
  {
    city: "gaborone",
    airport: "sir-seretse-khama-airport",
    airportCode: "GBE",
    country: "botswana",
  },
  {
    city: "gan-island",
    airport: "gan-airport",
    airportCode: "GAN",
    country: "maldives",
  },
  {
    city: "gaziantep",
    airport: "oguzeli-airport",
    airportCode: "GZT",
    country: "turkey",
  },
  {
    city: "gdansk",
    airport: "lech-walesa-airport",
    airportCode: "GDN",
    country: "poland",
  },
  {
    city: "geneva",
    airport: "geneva-airport",
    airportCode: "GVA",
    country: "switzerland",
  },
  {
    city: "genova",
    airport: "cristoforo-colombo-airport",
    airportCode: "GOA",
    country: "italy",
  },
  {
    city: "glasgow",
    airport: "glasgow-airport",
    airportCode: "GLA",
    country: "united-kingdom",
  },
  {
    city: "goa",
    airport: "dabolim-airport",
    airportCode: "GOI",
    country: "india",
  },
  {
    city: "gold-coast",
    airport: "coolangatta-airport",
    airportCode: "OOL",
    country: "australia",
  },
  {
    city: "goteborg",
    airport: "landvetter-airport",
    airportCode: "GOT",
    country: "sweden",
  },
  {
    city: "gran-canaria",
    airport: "gran-canaria-airport",
    airportCode: "LPA",
    country: "spain",
  },
  {
    city: "grand-rapids-MI",
    airport: "gerald-R-ford-airport",
    airportCode: "GRR",
    country: "usa",
  },
  {
    city: "graz",
    airport: "graz-airport",
    airportCode: "GRZ",
    country: "austria",
  },
  {
    city: "green-bay-WI",
    airport: "austin-straubel-airport",
    airportCode: "GRB",
    country: "usa",
  },
  {
    city: "greensboro-NC",
    airport: "piedmont-triad-airport",
    airportCode: "GSO",
    country: "usa",
  },
  {
    city: "greenville-SC",
    airport: "spartanburg-airport",
    airportCode: "GSP",
    country: "usa",
  },
  {
    city: "guangzhou",
    airport: "baiyun-airport",
    airportCode: "CAN",
    country: "china",
  },
  {
    city: "guwahati",
    airport: "lokpriya-gopinath-bordoloi-airport",
    airportCode: "GAU",
    country: "india",
  },
  {
    city: "halifax",
    airport: "stanfield-airport",
    airportCode: "YHZ",
    country: "canada",
  },
  {
    city: "hamburg",
    airport: "fuhlsbuettel-airport",
    airportCode: "HAM",
    country: "germany",
  },
  {
    city: "hamburg",
    airport: "hamburg-railway-station",
    airportCode: "ZMB",
    country: "germany",
  },
  {
    city: "hamilton-island",
    airport: "great-barrier-reef-airport",
    airportCode: "HTI",
    country: "australia",
  },
  {
    city: "hannover",
    airport: "hannover-airport",
    airportCode: "HAJ",
    country: "germany",
  },
  {
    city: "hanover",
    airport: "hannover-railway-station",
    airportCode: "ZVR",
    country: "germany",
  },
  {
    city: "harrisburg-PA",
    airport: "harrisburg-airport",
    airportCode: "MDT",
    country: "usa",
  },
  {
    city: "hartford-CT",
    airport: "bradley-airport",
    airportCode: "BDL",
    country: "usa",
  },
  {
    city: "helsinki",
    airport: "vantaa-airport",
    airportCode: "HEL",
    country: "finland",
  },
  {
    city: "heraklion",
    airport: "heraklion-nikos-kazantzakis-airport",
    airportCode: "HER",
    country: "greece",
  },
  {
    city: "ho-chi-minh-city",
    airport: "tan-son-nhat-airport",
    airportCode: "SGN",
    country: "vietnam",
  },
  {
    city: "hobart",
    airport: "hobart-airport",
    airportCode: "HBA",
    country: "australia",
  },
  {
    city: "hong-kong",
    airport: "hong-kong-airport",
    airportCode: "HKG",
    country: "china",
  },
  {
    city: "houston-TX",
    airport: "george-bush-airport",
    airportCode: "IAH",
    country: "usa",
  },
  {
    city: "Hurghada",
    airport: "hurghada-airport",
    airportCode: "HRG",
    country: "egypt",
  },
  {
    city: "hyannis-MA",
    airport: "barnstable-municipal-airport",
    airportCode: "HYA",
    country: "usa",
  },
  {
    city: "hyderabad",
    airport: "rajiv-gandhi-airport",
    airportCode: "HYD",
    country: "india",
  },
  {
    city: "ibiza",
    airport: "ibiza-airport",
    airportCode: "IBZ",
    country: "spain",
  },
  {
    city: "indianapolis-IN",
    airport: "indianapolis-airport",
    airportCode: "IND",
    country: "usa",
  },
  {
    city: "indore",
    airport: "devi-ahilyabai-holkar-airport",
    airportCode: "IDR",
    country: "india",
  },
  {
    city: "ioannina",
    airport: "ioannina-airport",
    airportCode: "IOA",
    country: "greece",
  },
  {
    city: "islamabad",
    airport: "new-islamabad-airport",
    airportCode: "ISB",
    country: "pakistan",
  },
  {
    city: "istanbul-sabiha-gokcen",
    airport: "sabiha-gokcen-airport",
    airportCode: "SAW",
    country: "turkey",
  },
  {
    city: "istanbul",
    airport: "istanbul-airport",
    airportCode: "IST",
    country: "turkey",
  },
  {
    city: "jacksonville-FL",
    airport: "jacksonville-airport",
    airportCode: "JAX",
    country: "usa",
  },
  {
    city: "jaipur",
    airport: "jaipur-airport",
    airportCode: "JAI",
    country: "india",
  },
  {
    city: "jakarta",
    airport: "soekarno-hatta-airport",
    airportCode: "CGK",
    country: "indonesia",
  },
  {
    city: "jeddah",
    airport: "king-abdulaziz-airport",
    airportCode: "JED",
    country: "saudi-arabia",
  },
  {
    city: "johannesburg",
    airport: "O-R-tambo-airport",
    airportCode: "JNB",
    country: "south-africa",
  },
  {
    city: "johor-bahru",
    airport: "sultan-ismail-airport",
    airportCode: "JHB",
    country: "malaysia",
  },
  {
    city: "kagoshima",
    airport: "kagoshima-airport",
    airportCode: "KOJ",
    country: "japan",
  },
  {
    city: "kalamazoo-MI",
    airport: "battle-creek-airport",
    airportCode: "AZO",
    country: "usa",
  },
  {
    city: "kalgoorlie",
    airport: "boulder-airport",
    airportCode: "KGI",
    country: "australia",
  },
  {
    city: "kansas-city-MO",
    airport: "kansas-city-airport",
    airportCode: "MCI",
    country: "usa",
  },
  {
    city: "kaohsiung",
    airport: "kaohsiung-airport",
    airportCode: "KHH",
    country: "china",
  },
  {
    city: "karachi",
    airport: "jinnah-airport",
    airportCode: "KHI",
    country: "pakistan",
  },
  {
    city: "karmoy",
    airport: "haugesund-airport",
    airportCode: "HAU",
    country: "norway",
  },
  {
    city: "kathmandu",
    airport: "tribhuvan-airport",
    airportCode: "KTM",
    country: "nepal",
  },
  {
    city: "kavala",
    airport: "alexander-the-great-airport",
    airportCode: "KVA",
    country: "greece",
  },
  {
    city: "kelowna",
    airport: "kelowna-airport",
    airportCode: "YLW",
    country: "canada",
  },
  {
    city: "kigali",
    airport: "kigali-airport",
    airportCode: "KGL",
    country: "rwanda",
  },
  {
    city: "knoxville-TN",
    airport: "mcghee-tyson-airport",
    airportCode: "TYS",
    country: "usa",
  },
  {
    city: "kochi",
    airport: "cochin-airport",
    airportCode: "COK",
    country: "india",
  },
  {
    city: "koh-samui",
    airport: "ko-samui-airport",
    airportCode: "USM",
    country: "thailand",
  },
  {
    city: "kolkata",
    airport: "netaji-subhas-chandra-bose-airport",
    airportCode: "CCU",
    country: "india",
  },
  {
    city: "kos",
    airport: "ippokratis-airport",
    airportCode: "KGS",
    country: "greece",
  },
  {
    city: "kota-bharu",
    airport: "sultan-ismail-petra-airport",
    airportCode: "KBR",
    country: "malaysia",
  },
  {
    city: "kota-kinabalu",
    airport: "kota-kinabalu-airport",
    airportCode: "BKI",
    country: "malaysia",
  },
  {
    city: "kozhikode",
    airport: "calicut-airport",
    airportCode: "CCJ",
    country: "india",
  },
  {
    city: "krabi",
    airport: "krabi-airport",
    airportCode: "KBV",
    country: "thailand",
  },
  {
    city: "krakow",
    airport: "john-paul-II-balice-airport",
    airportCode: "KRK",
    country: "poland",
  },
  {
    city: "kristiansand",
    airport: "kjevik-airport",
    airportCode: "KRS",
    country: "norway",
  },
  {
    city: "kuala-lumpur",
    airport: "kuala-lumpur-airport",
    airportCode: "KUL",
    country: "malaysia",
  },
  {
    city: "kuala-terengganu",
    airport: "sultan-mahmud-airport",
    airportCode: "TGG",
    country: "malaysia",
  },
  {
    city: "kuching",
    airport: "kuching-airport",
    airportCode: "KCH",
    country: "malaysia",
  },
  {
    city: "kuwait",
    airport: "kuwait-airport",
    airportCode: "KWI",
    country: "kuwait",
  },
  {
    city: "la-crosse-WI",
    airport: "la-crosse-airport",
    airportCode: "LSE",
    country: "usa",
  },
  {
    city: "labuan",
    airport: "labuan-airport",
    airportCode: "LBU",
    country: "malaysia",
  },
  {
    city: "lahore",
    airport: "allama-iqbal-airport",
    airportCode: "LHE",
    country: "pakistan",
  },
  {
    city: "lambert-MO",
    airport: "st-louis-airport",
    airportCode: "STL",
    country: "usa",
  },
  {
    city: "lamezia-terme",
    airport: "lamezia-terme-airport",
    airportCode: "SUF",
    country: "italy",
  },
  {
    city: "lampang",
    airport: "lampang-airport",
    airportCode: "LPT",
    country: "thailand",
  },
  {
    city: "lampedusa",
    airport: "lampedusa-airport",
    airportCode: "LMP",
    country: "italy",
  },
  {
    city: "langkawi",
    airport: "langkawi-airport",
    airportCode: "LGK",
    country: "malaysia",
  },
  {
    city: "lanzarote",
    airport: "lanzarote-airport",
    airportCode: "ACE",
    country: "spain",
  },
  {
    city: "larnaca",
    airport: "larnaca-airport",
    airportCode: "LCA",
    country: "cyprus",
  },
  {
    city: "las-vegas-NV",
    airport: "mccarran-airport",
    airportCode: "LAS",
    country: "usa",
  },
  {
    city: "launceston",
    airport: "launceston-airport",
    airportCode: "LST",
    country: "australia",
  },
  {
    city: "le-mans",
    airport: "le-mans-TGV-railway-station",
    airportCode: "ZLN",
    country: "france",
  },
  {
    city: "lecce",
    airport: "lecce-railway-station",
    airportCode: "LCZ",
    country: "italy",
  },
  {
    city: "leeds",
    airport: "leeds-bradford-airport",
    airportCode: "LBA",
    country: "united-kingdom",
  },
  {
    city: "leipzig",
    airport: "halle-airport",
    airportCode: "LEJ",
    country: "germany",
  },
  {
    city: "leipzig",
    airport: "leipzig/halle-railway-station",
    airportCode: "XIT",
    country: "germany",
  },
  {
    city: "lexington-KY",
    airport: "blue-grass-airport ",
    airportCode: "LEX",
    country: "usa",
  },
  {
    city: "lille,",
    airport: "TGV-railway-station ",
    airportCode: "XDB",
    country: "france",
  },
  {
    city: "lisbon",
    airport: "portela-airport",
    airportCode: "LIS",
    country: "portugal",
  },
  {
    city: "little-rock-AR",
    airport: "adams-field-airport",
    airportCode: "LIT",
    country: "usa",
  },
  {
    city: "lombok",
    airport: "selaparang-airport",
    airportCode: "LOP",
    country: "indonesia",
  },
  {
    city: "london",
    airport: "heathrow-airport",
    airportCode: "LHR",
    country: "united-kingdom",
  },
  {
    city: "los-angeles-CA",
    airport: "los-angeles-airport",
    airportCode: "LAX",
    country: "usa",
  },
  {
    city: "louisville-KY",
    airport: "standiford-field-airport",
    airportCode: "SDF",
    country: "usa",
  },
  {
    city: "lucknow",
    airport: "chaudhary-charan-singh-airport",
    airportCode: "LKO",
    country: "india",
  },
  {
    city: "lulea",
    airport: "lulea-airport",
    airportCode: "LLA",
    country: "sweden",
  },
  {
    city: "",
    airport: "",
    airportCode: "",
    country: "",
  },
  {
    city: "luxembourg",
    airport: "luxembourg-airport",
    airportCode: "LUX",
    country: "luxembourg",
  },
  {
    city: "lyon",
    airport: "part-dieu-TGV-railway-station",
    airportCode: "XYD",
    country: "france",
  },
  {
    city: "lyon",
    airport: "satolaos-saint-exupery-airport",
    airportCode: "LYS",
    country: "france",
  },
  {
    city: "mackay",
    airport: "mackay-airport",
    airportCode: "MKY",
    country: "australia",
  },
  {
    city: "madeira",
    airport: "funchal-santa-catarina-airport",
    airportCode: "FNC",
    country: "portugal",
  },
  {
    city: "madison-WI",
    airport: "dane-county-airport",
    airportCode: "MSN",
    country: "usa",
  },
  {
    city: "madrid",
    airport: "barajas-adolfo-suarez-airport",
    airportCode: "MAD",
    country: "spain",
  },
  {
    city: "makassar",
    airport: "sultan-hasanuddin-airport",
    airportCode: "UPG",
    country: "indonesia",
  },
  {
    city: "malaga",
    airport: "malaga-airport",
    airportCode: "AGP",
    country: "spain",
  },
  {
    city: "male",
    airport: "velana-airport",
    airportCode: "MLE",
    country: "maldives",
  },
  {
    city: "malta",
    airport: "luqa-airport",
    airportCode: "MLA",
    country: "malta",
  },
  {
    city: "manado",
    airport: "sam-ratulangi-airport",
    airportCode: "MDC",
    country: "indonesia",
  },
  {
    city: "manchester",
    airport: "manchester-airport",
    airportCode: "MAN",
    country: "united-kingdom",
  },
  {
    city: "mangalore",
    airport: "mangalore-airport",
    airportCode: "IXE",
    country: "india",
  },
  {
    city: "manhattan-KS",
    airport: "manhattan-airport",
    airportCode: "MHK",
    country: "usa",
  },
  {
    city: "manila",
    airport: "ninoy-aquino-airport",
    airportCode: "MNL",
    country: "philippines",
  },
  {
    city: "marquette-MI",
    airport: "sawyer-airport",
    airportCode: "MQT",
    country: "usa",
  },
  {
    city: "marrakech",
    airport: "menara-airport",
    airportCode: "RAK",
    country: "morocco",
  },
  {
    city: "marseille",
    airport: "marignane-airport",
    airportCode: "MRS",
    country: "france",
  },
  {
    city: "marseille",
    airport: "st-charles-TGV-railway-station",
    airportCode: "XRF",
    country: "france",
  },
  {
    city: "martha's-vineyard-MA",
    airport: "martha's-vineyard-airport",
    airportCode: "MVY",
    country: "usa",
  },
  {
    city: "medan",
    airport: "polonia-kualanamu-airport",
    airportCode: "KNO",
    country: "indonesia",
  },
  {
    city: "medellin",
    airport: "jose-maria-cordova-airport",
    airportCode: "MDE",
    country: "colombia",
  },
  {
    city: "medina",
    airport: "madinah-prince-mohammad-airport",
    airportCode: "MED",
    country: "saudi-arabia",
  },
  {
    city: "melbourne",
    airport: "tullamarine-airport",
    airportCode: "MEL",
    country: "australia",
  },
  {
    city: "memphis-TN",
    airport: "memphis-airport",
    airportCode: "MEM",
    country: "usa",
  },
  {
    city: "menorca",
    airport: "menorca-airport",
    airportCode: "MAH",
    country: "spain",
  },
  {
    city: "metz-nancy",
    airport: "lorraine-TGV-railway-station",
    airportCode: "XZI",
    country: "france",
  },
  {
    city: "miami-FL",
    airport: "miami-airport",
    airportCode: "MIA",
    country: "usa",
  },
  {
    city: "milan",
    airport: "linate-airport",
    airportCode: "LIN",
    country: "italy",
  },
  {
    city: "milan",
    airport: "malpensa-airport",
    airportCode: "MXP",
    country: "italy",
  },
  {
    city: "milan",
    airport: "milano-centrale-railway-station",
    airportCode: "XIK",
    country: "italy",
  },
  {
    city: "milan",
    airport: "rogoredo-railway-station",
    airportCode: "IMR",
    country: "italy",
  },
  {
    city: "milwaukee-WI",
    airport: "general-mitchell-airport",
    airportCode: "MKE",
    country: "usa",
  },
  {
    city: "minneapolis-MN",
    airport: "saint-paul-airport",
    airportCode: "MSP",
    country: "usa",
  },
  {
    city: "okinawa",
    airport: "naha-airport",
    airportCode: "OKA",
    country: "japan",
  },
  {
    city: "oklahoma-city-OK",
    airport: "will-rogers-world-airport",
    airportCode: "OKC",
    country: "usa",
  },
  {
    city: "omaha-NE",
    airport: "eppley-airfield",
    airportCode: "OMA",
    country: "usa",
  },
  {
    city: "orlando-FL",
    airport: "orlando-airport",
    airportCode: "MCO",
    country: "usa",
  },
  {
    city: "osaka",
    airport: "kansai-airport",
    airportCode: "KIX",
    country: "japan",
  },
  {
    city: "oslo",
    airport: "gardermoen-airport",
    airportCode: "OSL",
    country: "norway",
  },
  {
    city: "oslo",
    airport: "torp-sandefjord-airport",
    airportCode: "TRF",
    country: "norway",
  },
  {
    city: "ostersund",
    airport: "are-ostersund-airport",
    airportCode: "OSD",
    country: "sweden",
  },
  {
    city: "ottawa",
    airport: "macdonald-cartier-airport",
    airportCode: "YOW",
    country: "canada",
  },
  {
    city: "padang",
    airport: "minangkabau-airport",
    airportCode: "PDG",
    country: "indonesia",
  },
  {
    city: "palembang",
    airport: "sultan-mahmud-badaruddin-airport",
    airportCode: "PLM",
    country: "indonesia",
  },
  {
    city: "palermo",
    airport: "punta-raisi-airport",
    airportCode: "PMO",
    country: "italy",
  },
  {
    city: "palma-mallorca",
    airport: "palma-de-mallorca-airport",
    airportCode: "PMI",
    country: "spain",
  },
  {
    city: "panteleria",
    airport: "panteleria-airport",
    airportCode: "PNL",
    country: "italy",
  },
  {
    city: "paris",
    airport: "charles-de-gaulle-airport",
    airportCode: "CDG",
    country: "france",
  },
  {
    city: "patna",
    airport: "jay-prakash-narayan-airport",
    airportCode: "PAT",
    country: "india",
  },
  {
    city: "pekanbaru",
    airport: "sultan-syarif-kasim-airport",
    airportCode: "PKU",
    country: "indonesia",
  },
  {
    city: "penang",
    airport: "penang-airport",
    airportCode: "PEN",
    country: "malaysia",
  },
  {
    city: "peoria-IL",
    airport: "greater-peoria-airport",
    airportCode: "PIA",
    country: "usa",
  },
  {
    city: "perth",
    airport: "perth-airport",
    airportCode: "PER",
    country: "australia",
  },
  {
    city: "peshawar",
    airport: "bacha-khan-airport",
    airportCode: "PEW",
    country: "pakistan",
  },
  {
    city: "philadelphia-PA",
    airport: "philadelphia-airport",
    airportCode: "PHL",
    country: "usa",
  },
  {
    city: "phnom-penh",
    airport: "phnom-penh-airport",
    airportCode: "PNH",
    country: "cambodia",
  },
  {
    city: "phoenix-AZ",
    airport: "sky-harbor-airport",
    airportCode: "PHX",
    country: "usa",
  },
  {
    city: "milan",
    airport: "malpensa-airport",
    airportCode: "MXP",
    country: "italy",
  },
  {
    city: "minsk",
    airport: "minsk-2-national-airport",
    airportCode: "MSQ",
    country: "belarus",
  },
  {
    city: "miri",
    airport: "mir-airport",
    airportCode: "MYY",
    country: "malaysia",
  },
  {
    city: "moline-IL",
    airport: "quad-city-airport",
    airportCode: "MLI",
    country: "usa",
  },
  {
    city: "montpellier",
    airport: "st-roch-TGV-railway-station",
    airportCode: "XPJ",
    country: "france",
  },
  {
    city: "montreal",
    airport: "pierre-elliott-trudeau-airport",
    airportCode: "YUL",
    country: "canada",
  },
  {
    city: "moscow",
    airport: "sheremetyevo-airport",
    airportCode: "SVO",
    country: "russia",
  },
  {
    city: "mount-isa",
    airport: "mount-isa-airport",
    airportCode: "ISA",
    country: "australia",
  },
  {
    city: "multan",
    airport: "multan-airport",
    airportCode: "MUX",
    country: "pakistan",
  },
  {
    city: "mumbai",
    airport: "bombay-chhatrapati-shivaji-maharaj-airport",
    airportCode: "BOM",
    country: "india",
  },
  {
    city: "munich",
    airport: "franz-josef-strauss-airport",
    airportCode: "MUC",
    country: "germany",
  },
  {
    city: "munich",
    airport: "munich-railway-station",
    airportCode: "ZMU",
    country: "germany",
  },
  {
    city: "muscat",
    airport: "muscat-airport",
    airportCode: "MCT",
    country: "oman",
  },
  {
    city: "mykonos",
    airport: "mykonos-airport",
    airportCode: "JMK",
    country: "greece",
  },
  {
    city: "mytilene",
    airport: "mytilene-airport",
    airportCode: "MJT",
    country: "greece",
  },
  {
    city: "n'djamena",
    airport: "n'djamena-airport",
    airportCode: "NDJ",
    country: "chad",
  },
  {
    city: "nadi",
    airport: "nadi-airport",
    airportCode: "NAN",
    country: "fiji",
  },
  {
    city: "nagoya",
    airport: "chubu-centrair-airport",
    airportCode: "NGO",
    country: "japan",
  },
  {
    city: "nagpur",
    airport: "dr-babasaheb-ambedkar-airport",
    airportCode: "NAG",
    country: "india",
  },
  {
    city: "nairobi",
    airport: "jomo-kenyatta-airport",
    airportCode: "NBO",
    country: "kenya",
  },
  {
    city: "nantes",
    airport: "TGV-railway-station",
    airportCode: "QJZ",
    country: "france",
  },
  {
    city: "nantucket-MA",
    airport: "nantucket-memoria-airport",
    airportCode: "ACK",
    country: "usa",
  },
  {
    city: "napoli",
    airport: "capodichino-airport",
    airportCode: "NAP",
    country: "italy",
  },
  {
    city: "napoli",
    airport: "naples-central-railway-station",
    airportCode: "INP",
    country: "italy",
  },
  {
    city: "nashville-TN",
    airport: "nashville-airport",
    airportCode: "BNA",
    country: "usa",
  },
  {
    city: "new-delhi",
    airport: "indira-gandhi-airport",
    airportCode: "DEL",
    country: "india",
  },
  {
    city: "new-orleans-LA",
    airport: "louis-armstrong-airport",
    airportCode: "MSY",
    country: "usa",
  },
  {
    city: "new-york-NY",
    airport: "john-F-kennedy-airport",
    airportCode: "JFK",
    country: "usa",
  },
  {
    city: "new-york-NY",
    airport: "la-guardia-airport",
    airportCode: "LGA",
    country: "usa",
  },
  {
    city: "new-york-NY",
    airport: "newark-liberty-airport",
    airportCode: "EWR",
    country: "usa",
  },
  {
    city: "newcastle",
    airport: "newcastle-airport",
    airportCode: "NCL",
    country: "united-kingdom",
  },
  {
    city: "newcastle",
    airport: "williamtown-airport",
    airportCode: "NTL",
    country: "australia",
  },
  {
    city: "nice",
    airport: "cote-D-azur-airport",
    airportCode: "NCE",
    country: "france",
  },
  {
    city: "niigata",
    airport: "niigata-airport",
    airportCode: "KIJ",
    country: "japan",
  },
  {
    city: "nimes",
    airport: "nimes-TGV-railway-station",
    airportCode: "ZYN",
    country: "france",
  },
  {
    city: "norfolk-VA",
    airport: "norfolk-airport",
    airportCode: "ORF",
    country: "usa",
  },
  {
    city: "noumea",
    airport: "la-tontouta-airport",
    airportCode: "NOU",
    country: "new-caledonia",
  },
  {
    city: "nuremberg",
    airport: "nuernberg-railway-station",
    airportCode: "ZAQ",
    country: "germany",
  },
  {
    city: "nuremberg",
    airport: "nuremberg-airport",
    airportCode: "NUE",
    country: "germany",
  },
  {
    city: "odesa",
    airport: "odesa-airport",
    airportCode: "ODS",
    country: "ukraine",
  },
  {
    city: "okinawa",
    airport: "naha-airport",
    airportCode: "OKA",
    country: "japan",
  },
  {
    city: "phuket",
    airport: "phuket-airport",
    airportCode: "HKT",
    country: "thailand",
  },
  {
    city: "pittsburgh-PA",
    airport: "pittsburgh-airport",
    airportCode: "PIT",
    country: "usa",
  },
  {
    city: "plymouth",
    airport: "plymouth-city-airport",
    airportCode: "PLH",
    country: "united-kingdom",
  },
  {
    city: "poitiers",
    airport: "poitiers-TGV-railway-station",
    airportCode: "XOP",
    country: "france",
  },
  {
    city: "ponta-delgada",
    airport: "joao-paulo-II-airport",
    airportCode: "PDL",
    country: "portugal",
  },
  {
    city: "port-elizabeth",
    airport: "port-elizabeth-airport",
    airportCode: "PLZ",
    country: "south-africa",
  },
  {
    city: "portland-ME",
    airport: "portland-jetport",
    airportCode: "PWM",
    country: "usa",
  },
  {
    city: "porto",
    airport: "francisco-sa-carneiro-airport",
    airportCode: "OPO",
    country: "portugal",
  },
  {
    city: "prague",
    airport: "vaclav-havel-ruzyne-airport",
    airportCode: "PRG",
    country: "czech-republic",
  },
  {
    city: "proserpine",
    airport: "whitsunday-coast-airport",
    airportCode: "PPP",
    country: "australia",
  },
  {
    city: "pula",
    airport: "pula-airport",
    airportCode: "PUY",
    country: "croatia",
  },
  {
    city: "pune",
    airport: "lohegaon-airport",
    airportCode: "PNQ",
    country: "india",
  },
  {
    city: "qingdao",
    airport: "liuting-airport",
    airportCode: "TAO",
    country: "china",
  },
  {
    city: "queenstown",
    airport: "queenstown-airport",
    airportCode: "ZQN",
    country: "new-zealand",
  },
  {
    city: "rail-n-fly",
    airport: "DB-german-railway-service",
    airportCode: "QYG",
    country: "germany",
  },
  {
    city: "raipur",
    airport: "swami-vivekananda-airport",
    airportCode: "RPR",
    country: "india",
  },
  {
    city: "raleigh-durham-NC",
    airport: "durham-airport",
    airportCode: "RDU",
    country: "usa",
  },
  {
    city: "ranchi",
    airport: "birsa-munda-airport",
    airportCode: "IXR",
    country: "india",
  },
  {
    city: "regina",
    airport: "regina-airport",
    airportCode: "YQR",
    country: "canada",
  },
  {
    city: "reims",
    airport: "champagne-ardenne-TGV-railway-station",
    airportCode: "XIZ",
    country: "france",
  },
  {
    city: "rennes",
    airport: "rennes-TGV-railway-station",
    airportCode: "ZFJ",
    country: "france",
  },
  {
    city: "reno-NV",
    airport: "tahoe-airpor",
    airportCode: "RNO",
    country: "usa",
  },
  {
    city: "reykjavik",
    airport: "keflavik-airport",
    airportCode: "KEF",
    country: "iceland",
  },
  {
    city: "rhodes",
    airport: "diagoras-airport",
    airportCode: "RHO",
    country: "greece",
  },
  {
    city: "richmond-VA",
    airport: "richmond-airport",
    airportCode: "RIC",
    country: "usa",
  },
  {
    city: "riga",
    airport: "riga-airport",
    airportCode: "RIX",
    country: "latvia",
  },
  {
    city: "riyadh",
    airport: "king-khalid-airport",
    airportCode: "RUH",
    country: "saudi-arabia",
  },
  {
    city: "rochester-MN",
    airport: "rochester-airport",
    airportCode: "RST",
    country: "usa",
  },
  {
    city: "rochester-NY",
    airport: "greater-rochester-airport",
    airportCode: "ROC",
    country: "usa",
  },
  {
    city: "rockhampton",
    airport: "rockhampton-airport",
    airportCode: "ROK",
    country: "australia",
  },
  {
    city: "rome",
    airport: "fiumicino-leonardo-da-vinci-airport",
    airportCode: "FCO",
    country: "italy",
  },
  {
    city: "rotorua",
    airport: "rotorua-airport",
    airportCode: "ROT",
    country: "new-zealand",
  },
  {
    city: "salalah",
    airport: "salalah-airport",
    airportCode: "SLL",
    country: "Oman",
  },
  {
    city: "salt-lake-city-UT",
    airport: "salt-lake-city-airport",
    airportCode: "SLC",
    country: "usa",
  },
  {
    city: "samos",
    airport: "samos-airport",
    airportCode: "SMI",
    country: "greece",
  },
  {
    city: "san-antonio-TX",
    airport: "san-antonio-airport",
    airportCode: "SAT",
    country: "usa",
  },
  {
    city: "san-diego-CA",
    airport: "lindbergh-field-airport",
    airportCode: "SAN",
    country: "usa",
  },
  {
    city: "san-francisco-CA",
    airport: "san-francisco-airport",
    airportCode: "SFO",
    country: "usa",
  },
  {
    city: "san-jose-CA",
    airport: "norman-Y-mineta-airport",
    airportCode: "SJC",
    country: "usa",
  },
  {
    city: "sandakan",
    airport: "sandakan-airport",
    airportCode: "SDK",
    country: "malaysia",
  },
  {
    city: "santorini",
    airport: "santorini-airport",
    airportCode: "JTR",
    country: "greece",
  },
  {
    city: "sapporo",
    airport: "chitose-airport",
    airportCode: "CTS",
    country: "japan",
  },
  {
    city: "sarajevo",
    airport: "butmir-airport",
    airportCode: "SJJ",
    country: "bosnia-herzegovina",
  },
  {
    city: "sarasota-FL",
    airport: "bradenton-airport",
    airportCode: "SRQ",
    country: "usa",
  },
  {
    city: "saskatoon",
    airport: "JG-diefenbaker-airport",
    airportCode: "YXE",
    country: "canada",
  },
  {
    city: "savannah-GA",
    airport: "hilton-head-airport",
    airportCode: "SAV",
    country: "usa",
  },
  {
    city: "seattle-WA",
    airport: "tacoma-airport",
    airportCode: "SEA",
    country: "usa",
  },
  {
    city: "semarang",
    airport: "achmad-yani-airport",
    airportCode: "SRG",
    country: "indonesia",
  },
  {
    city: "sendai",
    airport: "sendai-airport",
    airportCode: "SDJ",
    country: "japan",
  },
  {
    city: "seoul",
    airport: "incheon-airport",
    airportCode: "ICN",
    country: "south-korea",
  },
  {
    city: "seville",
    airport: "seville-airport",
    airportCode: "SVQ",
    country: "spain",
  },
  {
    city: "seychelles",
    airport: "mahe-airport",
    airportCode: "SEZ",
    country: "seychelles",
  },
  {
    city: "shanghai",
    airport: "pudong-airport",
    airportCode: "PVG",
    country: "china",
  },
  {
    city: "shannon",
    airport: "shannon-airport",
    airportCode: "SNN",
    country: "ireland",
  },
  {
    city: "sharm-el-sheikh",
    airport: "sharm-el-sheikh-airport",
    airportCode: "SSH",
    country: "egypt",
  },
  {
    city: "shenyang",
    airport: "taoxian-airport",
    airportCode: "SHE",
    country: "china",
  },
  {
    city: "shenzhen",
    airport: "bao'an-airport",
    airportCode: "SZX",
    country: "china",
  },
  {
    city: "siem-reap",
    airport: "angkor-airport",
    airportCode: "REP",
    country: "cambodia",
  },
  {
    city: "simferopol",
    airport: "simferopol-airport",
    airportCode: "SIP",
    country: "ukraine",
  },
  {
    city: "singapore",
    airport: "changi-airport",
    airportCode: "SIN",
    country: "singapore",
  },
  {
    city: "sioux-city-IA",
    airport: "sioux-gateway-airport",
    airportCode: "SUX",
    country: "usa",
  },
  {
    city: "sioux-falls-SD",
    airport: "joe-foss-field-airport",
    airportCode: "FSD",
    country: "usa",
  },
  {
    city: "sofia",
    airport: "sofia-airport",
    airportCode: "SOF",
    country: "bulgaria",
  },
  {
    city: "sohag",
    airport: "sohag-airport",
    airportCode: "HMB",
    country: "egypt",
  },
  {
    city: "solo",
    airport: "adi-sumarmo-wiryokusumo-airport",
    airportCode: "SOC",
    country: "indonesia",
  },
  {
    city: "venice",
    airport: "marco-polo-airport",
    airportCode: "VCE",
    country: "italy",
  },
  {
    city: "venice",
    airport: "venice-mestre-train-station",
    airportCode: "XVY",
    country: "italy",
  },
  {
    city: "verona",
    airport: "villafranca-airport",
    airportCode: "VRN",
    country: "italy",
  },
  {
    city: "victoria",
    airport: "victoria-airport",
    airportCode: "YYJ",
    country: "canada",
  },
  {
    city: "vienna",
    airport: "schwechat-airport",
    airportCode: "VIE",
    country: "austria",
  },
  {
    city: "vigo",
    airport: "vigo-airport",
    airportCode: "VGO",
    country: "spain",
  },
  {
    city: "vijayawada",
    airport: "vijayawada-airport",
    airportCode: "VGA",
    country: "india",
  },
  {
    city: "vilnius",
    airport: "vilnius-airport",
    airportCode: "VNO",
    country: "lithuania",
  },
  {
    city: "vishakhapatnam",
    airport: "visakhapatnam-airport",
    airportCode: "VTZ",
    country: "india",
  },
  {
    city: "warsaw",
    airport: "chopin-okecie-airport",
    airportCode: "WAW",
    country: "poland",
  },
  {
    city: "washington-DC",
    airport: "dulles-airport",
    airportCode: "IAD",
    country: "usa",
  },
  {
    city: "washington-DC",
    airport: "ronald-reagan-national-airport",
    airportCode: "DCA",
    country: "usa",
  },
  {
    city: "waterloo-IA",
    airport: "waterloo-airport",
    airportCode: "ALO",
    country: "usa",
  },
  {
    city: "wellington",
    airport: "wellington-airport",
    airportCode: "WLG",
    country: "new-zealand",
  },
  {
    city: "west-palm-beach-FL",
    airport: "palm-beach-airport",
    airportCode: "PBI",
    country: "usa",
  },
  {
    city: "westchester-county-NY",
    airport: "westchester-county-airport",
    airportCode: "HPN",
    country: "usa",
  },
  {
    city: "wichita-KS",
    airport: "mid-continent-airport",
    airportCode: "ICT",
    country: "usa",
  },
  {
    city: "winnipeg",
    airport: "winnipeg-airport",
    airportCode: "YWG",
    country: "canada",
  },
  {
    city: "wroclaw",
    airport: "nicolaus-copernicus-airport",
    airportCode: "WRO",
    country: "poland",
  },
  {
    city: "xian",
    airport: "xianyang-airport",
    airportCode: "XIY",
    country: "china",
  },
  {
    city: "yerevan",
    airport: "zvartnots-airport",
    airportCode: "EVN",
    country: "armenia",
  },
  {
    city: "zagreb",
    airport: "pleso-airport",
    airportCode: "ZAG",
    country: "Croatia",
  },
  {
    city: "zanzibar",
    airport: "zanzibar-airport",
    airportCode: "ZNZ",
    country: "tanzania",
  },
  {
    city: "zurich",
    airport: "zurich-airport",
    airportCode: "ZRH",
    country: "switzerland",
  },
  {
    city: "split",
    airport: "split-airport",
    airportCode: "SPU",
    country: "croatia",
  },
  {
    city: "springfield-MO",
    airport: "branson-airport",
    airportCode: "SGF",
    country: "usa",
  },
  {
    city: "srinagar",
    airport: "srinagar-airport",
    airportCode: "SXR",
    country: "india",
  },
  {
    city: "st-john's",
    airport: "st-john's-airport",
    airportCode: "YYT",
    country: "canada",
  },
  {
    city: "stavanger",
    airport: "sola-airport",
    airportCode: "SVG",
    country: "norway",
  },
  {
    city: "stockholm",
    airport: "arlanda-airport",
    airportCode: "ARN",
    country: "sweden",
  },
  {
    city: "stockholm",
    airport: "bromma-airport",
    airportCode: "BMA",
    country: "sweden",
  },
  {
    city: "strasbourg",
    airport: "TGV-railway-station",
    airportCode: "XWG",
    country: "france",
  },
  {
    city: "stuttgart",
    airport: "stuttgart-railway-station",
    airportCode: "ZWS",
    country: "germany",
  },
  {
    city: "sukhothai",
    airport: "sukhothai-airport",
    airportCode: "THS",
    country: "thailand",
  },
  {
    city: "sundsvall",
    airport: "timra-airport",
    airportCode: "SDL",
    country: "sweden",
  },
  {
    city: "sunshine-coast",
    airport: "maroochydore-airport",
    airportCode: "MCY",
    country: "australia",
  },
  {
    city: "surabaya",
    airport: "juanda-airport",
    airportCode: "SUB",
    country: "indonesia",
  },
  {
    city: "swindon",
    airport: "swindon-railway-station",
    airportCode: "XWS",
    country: "united-kingdom",
  },
  {
    city: "sydney",
    airport: "kingsford-smith-airport",
    airportCode: "SYD",
    country: "australia",
  },
  {
    city: "syracuse-NY",
    airport: "clarence-E-hancock-airport",
    airportCode: "SYR",
    country: "usa",
  },
  {
    city: "taichung",
    airport: "taichung-airport",
    airportCode: "RMQ",
    country: "china",
  },
  {
    city: "taipei",
    airport: "taoyuan-airport",
    airportCode: "TPE",
    country: "china",
  },
  {
    city: "tallinn",
    airport: "lennart-meri-airport",
    airportCode: "TLL",
    country: "estonia",
  },
  {
    city: "tampa-FL",
    airport: "tampa-airport",
    airportCode: "TPA",
    country: "usa",
  },
  {
    city: "tangier",
    airport: "boukhalef-ibn-battouta-airport",
    airportCode: "TNG",
    country: "morocco",
  },
  {
    city: "taunton",
    airport: "taunton-bus-station",
    airportCode: "TTY",
    country: "united-kingdom",
  },
  {
    city: "tawau",
    airport: "tawau-airport",
    airportCode: "TWU",
    country: "malaysia",
  },
  {
    city: "tbilisi",
    airport: "tbilisi-airport ",
    airportCode: "TBS",
    country: "georgia",
  },
  {
    city: "tel-aviv",
    airport: "ben-gurion-airport",
    airportCode: "TLV",
    country: "israel",
  },
  {
    city: "tenerife",
    airport: "tenerife-north-airport",
    airportCode: "TFN",
    country: "spain",
  },
  {
    city: "thessaloniki",
    airport: "makedonia-airport",
    airportCode: "SKG",
    country: "greece",
  },
  {
    city: "thiruvananthapuram",
    airport: "trivandrum-airport",
    airportCode: "TRV",
    country: "india",
  },
  {
    city: "thunder-bay",
    airport: "thunder-bay-airport",
    airportCode: "YQT",
    country: "canada",
  },
  {
    city: "tirana",
    airport: "rinas-mother-teresa-airport",
    airportCode: "TIA",
    country: "albania",
  },
  {
    city: "tokyo",
    airport: "narita-airport",
    airportCode: "NRT",
    country: "japan",
  },
  {
    city: "toledo-OH",
    airport: "express-airport",
    airportCode: "TOL",
    country: "usa",
  },
  {
    city: "toronto",
    airport: "pearson-airport",
    airportCode: "YYZ",
    country: "canada",
  },
  {
    city: "toulouse",
    airport: "blagnac-airport",
    airportCode: "TLS",
    country: "france",
  },
  {
    city: "tours",
    airport: "saint-pierre-des-corps-railway-station",
    airportCode: "XSH",
    country: "france",
  },
  {
    city: "townsville",
    airport: "townsville-airport",
    airportCode: "TSV",
    country: "australia",
  },
  {
    city: "trat",
    airport: "trat-airport",
    airportCode: "TDX",
    country: "thailand",
  },
  {
    city: "traverse-city-MI",
    airport: "cherry-capital-airport",
    airportCode: "TVC",
    country: "usa",
  },
  {
    city: "trieste",
    airport: "ronchi-dei-legionari-airport",
    airportCode: "TRS",
    country: "italy",
  },
  {
    city: "trondheim",
    airport: "vaernes-airport",
    airportCode: "TRD",
    country: "norway",
  },
  {
    city: "tucson-AZ",
    airport: "tucson-airport",
    airportCode: "TUS",
    country: "usa",
  },
  {
    city: "tulsa-OK",
    airport: "tulsa-airport",
    airportCode: "TUL",
    country: "usa",
  },
  {
    city: "tunis",
    airport: "carthage-airport",
    airportCode: "TUN",
    country: "tunisia",
  },
  {
    city: "turin",
    airport: "caselle-airport",
    airportCode: "TRN",
    country: "italy",
  },
  {
    city: "udaipur",
    airport: "maharana-pratap-airport",
    airportCode: "UDR",
    country: "india",
  },
  {
    city: "umea",
    airport: "umea-airport",
    airportCode: "UME",
    country: "sweden",
  },
  {
    city: "vadodara",
    airport: "vadodara-airport",
    airportCode: "BDQ",
    country: "india",
  },
  {
    city: "valence",
    airport: "valence-TGV-railway-station",
    airportCode: "XHK",
    country: "france",
  },
  {
    city: "valencia",
    airport: "valencia-airport",
    airportCode: "VLC",
    country: "spain",
  },
  {
    city: "vancouver",
    airport: "vancouver-airport",
    airportCode: "YVR",
    country: "canada",
  },
  {
    city: "varanasi",
    airport: "lal-bahadur-shastri-airport",
    airportCode: "VNS",
    country: "india",
  },
  {
    city: "",
    airport: "",
    airportCode: "",
    country: "",
  },
];
