export const otherVisaCountries = [
  {
    name: "Australia",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2Faurtalia.webp?alt=media&token=07a80d82-3e2b-4332-9b50-e2e01b1fdb0d",
  },
  {
    name: "Canada",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2Fcanada.png?alt=media&token=cb687dda-ed28-4357-8a41-95cabc3dfed9",
  },
  {
    name: "Turkey",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2Fturkey.png?alt=media&token=bc8c5234-5001-4758-97c7-78202862e0a9",
  },
  {
    name: "USA",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2Fusa.png?alt=media&token=39849a61-106b-4cf0-aa55-80ad2c40fbe9",
  },
];

export const visaCounties = [
  {
    name: "austria",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FAustria.png?alt=media&token=05072129-802c-40af-8240-875c62adaf67",
  },
  {
    name: "belgium",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FBelgium.png?alt=media&token=b1506300-ee08-404b-85da-2605d32f5b13",
  },
  {
    name: "croatia",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FCroatia.png?alt=media&token=7305d25c-3176-48f7-ac36-9ab9bc45f322",
  },
  {
    name: "Czech Republic",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FCzech%20Republic.png?alt=media&token=08dbde84-846b-40a0-8d1b-7abb47f17bf3",
  },
  {
    name: "denmark",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FDenmark.png?alt=media&token=f1edb60c-05ef-4a8b-8b1f-11d41843d782",
  },

  {
    name: "Estonia",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FEstonia.png?alt=media&token=b3315420-f96c-4625-a8aa-47e642d4d9d5",
  },
  {
    name: "finland",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FFinland.png?alt=media&token=6a3ce71a-5924-4b6d-8b34-74abdf278b72",
  },
  {
    name: "France",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FFrance.png?alt=media&token=f721de69-dbfe-4ec0-b052-a8594ba90cbf",
  },

  {
    name: "Germany",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FGermany.webp?alt=media&token=8631af67-d8c6-4d9a-bfa7-8066f7f0f540",
  },
  {
    name: "Greece",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FGreece.webp?alt=media&token=26a836d0-f19e-425f-9e9c-41f639ee75ba",
  },
  {
    name: "Hungary",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FHungary.png?alt=media&token=03148b03-af52-4749-be4e-298e3948bccd",
  },
  {
    name: "Iceland",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FIceland.png?alt=media&token=efa36def-ff7d-4f0a-9d36-fab38bc694c2",
  },
  {
    name: "Italy",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FItaly.png?alt=media&token=948a1462-48a8-4456-8c19-6ac772f4c9db",
  },
  {
    name: "Latvia",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FLatvia.png?alt=media&token=555b7dae-e5b9-4448-b599-cb6ec7ab0e3b",
  },
  {
    name: "Liechtenstein",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FLiechtenstein.png?alt=media&token=2f202f50-53c3-4f80-b66e-c53c4d2cf55d",
  },
  {
    name: "Lithuania",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FLithuania.png?alt=media&token=7b5ebffe-cb9b-44d3-83df-745de9d1a238",
  },
  {
    name: "Luxembourg",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FLuxembourg.png?alt=media&token=61d8ceaa-0566-40c2-954c-69635da5d06c",
  },
  {
    name: "Malta",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FMalta.png?alt=media&token=ad9e6cab-30cd-480f-99ae-42985aab8c93",
  },
  {
    name: "Netherlands",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FNetherlands.png?alt=media&token=9803f67a-db43-44ce-9d03-9fc4405de8bc",
  },
  {
    name: "Norway",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FNorway.png?alt=media&token=7d68e537-0b14-4ada-a545-16d2680b9100",
  },
  {
    name: "Poland",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FPoland.png?alt=media&token=41922c2f-3fd2-4638-82f5-16cfedd5be59",
  },
  {
    name: "Portugal",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FPortugal.png?alt=media&token=95cef353-c327-4d17-90c1-abcf269bbbb5",
  },
  {
    name: "Slovakia",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FSlovakia.png?alt=media&token=d9452e39-ce51-4dec-8d68-d6660aaf8d67",
  },
  {
    name: "Slovenia",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FSlovenia.png?alt=media&token=572a3478-1874-413b-9d97-92d33ee2b1ab",
  },
  {
    name: "Spain",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FSpain.png?alt=media&token=2e1b3f90-c539-4bc7-ab12-f878aa055251",
  },
  {
    name: "Sweden",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FSweden.png?alt=media&token=180f5c24-f60d-4fa9-bf30-c31096b13135",
  },
  {
    name: "Switzerland",
    img: "https://firebasestorage.googleapis.com/v0/b/euroholidays-55335.appspot.com/o/visa-countries%2FSwitzerland.png?alt=media&token=f2d76b56-6399-483a-873b-e47caa8bcf46",
  },
];
